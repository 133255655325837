import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Card from "../../card/Card";
import Loading from "../../loader/Loading";
import {
  createBrand,
  getBrands,
  getCategories,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";

const CreateBrand = () => {
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const { isLoading, categories } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const saveBrand = async (e) => {
    e.preventDefault(); 

    if (name.length < 2) {
      return toast.error("Brand Name should be more than 3 characters");
    }
    if (!category) {
      return toast.error("Please select a category");
    }

    const formData = {
      name,
      category,
    };
    await dispatch(createBrand(formData));
    await dispatch(getBrands());
    setName("");
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="--mb2">
        <h3>Create Brand</h3>
        <p>
          Form to <b>Create a Brand</b>
        </p>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveBrand}>
            <label>Brand Name</label>
            <input
              type="text"
              placeholder="Enter Brand Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />

            <label>Parent category</label>
            <select
              name="category"
              className="form-control"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="">Select a category</option>
              {categories.length > 0 &&
                categories.map((category) => (
                  <option key={category._id} value={category.name}>
                    {category.name}
                  </option>
                ))}
            </select>

            <div className="--my">
              <button type="submit" className="--btn --btn-primary">
                Create Brand
              </button>
              {/* <button type="reset" className="btn btn-secondary">
          Reset
        </button> */}
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateBrand;
