import React from "react";
import BulkPurchases from "../../components/condtions/BulkPurchases";
import AboutFooter from "../../components/aboutus/AboutFooter";

const BulkPurchase = () => {
  return (
    <div>
      <BulkPurchases />
      <AboutFooter />
    </div>
  );
};

export default BulkPurchase;
