import React from "react";
import "./BulkPurchase.scss";

const BulkPurchases = () => {
  return (
    <div className="bulk-purchase-container">
      <h1>Bulk Purchase Policy for Elilock Integrated Services</h1>
      <p className="intro">
        At Elilock Integrated Services, we offer special pricing and terms for
        bulk purchases of our products, including smart locks, mortise locks,
        ironeries, and cylinders. This policy outlines the terms and conditions
        for bulk purchases.
      </p>

      <div className="section">
        <h2>1. Eligibility for Bulk Purchase</h2>
        <p>
          Bulk purchase discounts are available for orders meeting the following
          criteria:
          <ul>
            <li>
              Minimum order quantity of <strong>50 units</strong> per product.
            </li>
            <li>
              Orders must be placed by registered businesses or organizations.
            </li>
            <li>
              Custom orders or special requests may require additional lead time
              and costs.
            </li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>2. Pricing and Discounts</h2>
        <p>
          <strong>Discounts:</strong> Bulk purchase discounts are applied based
          on the quantity ordered. Discount rates are as follows:
          <ul>
            <li>
              50 - 100 units: <strong>10% discount</strong>.
            </li>
            <li>
              101 - 200 units: <strong>15% discount</strong>.
            </li>
            <li>
              201+ units: <strong>20% discount</strong>.
            </li>
          </ul>
          <strong>Note:</strong> Discounts are applicable only to the product
          price and do not include shipping, installation, or additional
          services.
        </p>
      </div>

      <div className="section">
        <h2>3. Payment Terms</h2>
        <p>
          <strong>Payment Methods:</strong> We accept payment via bank transfer,
          credit card, or other agreed-upon methods.
          <br />
          <strong>Deposit:</strong> A <strong>50% deposit</strong> is required
          to confirm your bulk order. The remaining balance must be paid before
          delivery.
          <br />
          <strong>Invoicing:</strong> Invoices will be issued upon order
          confirmation and must be paid within <strong>7 business days</strong>.
        </p>
      </div>

      <div className="section">
        <h2>4. Delivery and Shipping</h2>
        <p>
          <strong>Delivery Time:</strong> Delivery timelines vary based on
          product availability and order size. Standard delivery time is{" "}
          <strong>1-2 weeks</strong>.
          <br />
          <strong>Shipping Costs:</strong> Shipping costs are calculated based
          on the delivery location and order size. A shipping quote will be
          provided upon order confirmation.
          <br />
          <strong>Customs and Duties:</strong> For international orders,
          customers are responsible for any customs duties, taxes, or import
          fees.
        </p>
      </div>

      <div className="section">
        <h2>5. Returns and Refunds</h2>
        <p>
          <strong>Returns:</strong> Bulk purchases are eligible for returns only
          if the products are defective or damaged upon delivery. Returns must
          be requested within <strong>3 business days</strong> of delivery.
          <br />
          <strong>Refunds:</strong> Refunds will be processed within{" "}
          <strong>7 business days</strong> of receiving the returned products.
          Refunds are subject to inspection and approval.
          <br />
          <strong>Non-Returnable Items:</strong> Custom orders or products that
          have been used or installed are not eligible for returns or refunds.
        </p>
      </div>

      <div className="section">
        <h2>6. Contact Us</h2>
        <p>
          For inquiries or to place a bulk order, please contact us at:
          <br />
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      <p className="thank-you">
        Thank you for choosing Elilock Integrated Services!
      </p>
    </div>
  );
};

export default BulkPurchases;
