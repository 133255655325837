// export const shortenText = (text, n) => {
//   if (text.length > n) {
//     const shortenedText = text.substring(0, n).concat("...");
//     return shortenedText;
//   }
//   return text;
// };

export function shortenText(input, maxLength) {
  if (input && input.length > maxLength) {
    return input.substring(0, maxLength) + "...";
  }
  return input;
}

const regexValidator = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
export const validateEmail = (email) => {
  return email.match(regexValidator);
};

// calculate average product rating

export function calculateAverageRating(ratings) {
  if (!Array.isArray(ratings) || ratings.length === 0) {
    // return 0 if the ratings array is empty or not an array
    return 0;
  }

  var totalStars = 0;
  for (var i = 0; i < ratings.length; i++) {
    var rating = ratings[1];
    if (rating.hasOwnProperty("star")) {
      totalStars += rating.star;
    }
  }

  return totalStars / ratings.length;
}

export const getCartQuantityById = (products, id) => {
  for (let i = 0; i < products.length; i++) {
    if (products[i]._id === id) {
      return products[i].cartQuantity;
    }
  }
  return 0; //if the _id is not found, return 0 or any default value
};

// Extract id and cart quantity from cartItems
export function extractIdAndCartQuantity(products) {
  return products.map(function (product) {
    return {
      _id: product._id,
      cartQuantity: product.cartQuantity,
    };
  });
}
