import React from "react";
import ContactUs from "../../components/contactus/ContactUs";
import FooterLink from "../../components/footer/FooterLink";

import "./Contact.css";

const Contact = () => {
  return (
    <div className="contactbg">
      <div className="">
        <ContactUs />
      </div>
      <FooterLink />
    </div>
  );
};

export default Contact;
