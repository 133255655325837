import React from "react";

import styles from "./Footer.module.scss";

const Footer = () => {
  const date = new Date();
  const year = date.getFullYear();
  const copyright = `${year}`;

  return (
    <div className={styles.footer}>
      All the copyright reserved by Elilock Integrated Services. &copy;
      {copyright}
    </div>
  );
};

export default Footer;
