import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";
import "./NewSlider.scss";

const slideDdata = [
  {
    imgSrc: "https://i.ibb.co/NZyr6XH/smartlocks.jpg",
    title: "Top Quality Smart Locks",
    description:
      "This unique smart locks features fingerprint unlock, face unlock, manual key unlock",
    categories: ["Smart Lock", "Tuya App", "Face Unlock", "FingerPrint Unlock"],
    link: "/shop",
  },
  {
    imgSrc: "https://i.ibb.co/YWx7Rbh/smrt13.jpg",
    title: "Gold Strong Color Mortise Lock",
    description: "High quality Mortise to beautify your entrance",
    categories: ["Mortise Lock", "Gold Color", "Durable"],
    link: "/shop",
  },
  {
    imgSrc: "https://i.ibb.co/WB5JzMZ/smrt12.jpg",
    title: "Strong door Ironery",
    description:
      "This is one of our best selling door hardware elevate your door entrance",
    categories: ["Ironery", "Door Locks", ""],
    link: "/shop",
  },
  {
    imgSrc: "https://i.ibb.co/qkwWvTJ/sl9.png",
    title: "Tuya App Smart Lock",
    description: "Another best seller smart lock you don't want to miss",
    categories: [
      "Smart Lock",
      "Tuya App",
      "Face & Fingerprint Unlock",
      "Safety",
    ],
  },
  {
    imgSrc: "https://i.ibb.co/J7Sqzms/cdcsdcd.jpg",
    title: "Silver Color Door Handle",
    description: "This is another amazing door handle you can buy.",
    categories: ["Mortise Locks", "Door Handle"],
  },
  {
    imgSrc: "https://i.ibb.co/M5j4wsp/scssc.jpg",
    title: "High Grade Smart Lock",
    description:
      "This is a top of the line smart lock, with amazing features like 360 view of your enviroment, and a secure lock system",
    categories: ["Smart Lock", "smart lock app", "Smart", "Modern home"],
  },
];

export default function NewSlide() {
  const swiperWrappedRef = useRef(null);

  function adjustMargin() {
    const screenWidth = window.innerWidth;

    if (swiperWrappedRef.current) {
      swiperWrappedRef.current.style.marginLeft =
        screenWidth <= 520
          ? "0px"
          : screenWidth <= 650
          ? "-50px"
          : screenWidth <= 800
          ? "-100px"
          : "-150px";
    }
  }

  useEffect(() => {
    adjustMargin();
    window.addEventListener("resize", adjustMargin);
    return () => window.removeEventListener("resize", adjustMargin);
  }, []);
  return (
    <div className="mainrr">
      <div className="containerrr">
        <Swiper
          modules={[Pagination]}
          grabCursor
          initialSlide={2}
          centeredSlides
          slidesPerView="auto"
          speed={800}
          slideToClickedSlide
          pagination={{ clickable: true }}
          breakpoints={{
            320: { spaceBetween: 40 },
            650: { spaceBetween: 30 },
            1000: { spaceBetween: 20 },
          }}
          onSwiper={(swiper) => {
            swiperWrappedRef.current = swiper.wrapperEl;
          }}
        >
          {slideDdata.map((slide, index) => (
            <SwiperSlide key={index}>
              <img src={slide.imgSrc} alt={slide.title} className="imgrr" />
              <div className="titlerr">
                <h1>{slide.title}</h1>
              </div>
              <div className="contentrr">
                <div className="text-boxrr">
                  <p>{slide.description}</p>
                </div>
                <div className="footerrr">
                  <div className="categoryrr">
                    {slide.categories.map((category, idx) => (
                      <span key={idx} style={{ "--i": idx + 1 }}>
                        {category}
                      </span>
                    ))}
                  </div>
                  <Link to={slideDdata.link}>
                    <button className="btnrr">
                      <span className="labelrr">Buy Now</span>
                    </button>
                  </Link>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}
