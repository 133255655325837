import React from "react";

import ServicesOne from "./ServicesOne";



const OurServices = () => {
  return (
    <div className="App">
      <div className="about-section-bg paddings">
        <ServicesOne />
      </div>
    </div>
  );
};

export default OurServices;
