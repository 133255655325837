import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";
import { toast } from "react-toastify";
import { FaHeart } from "react-icons/fa6";

import {
  getProduct,
  getProducts,
} from "../../../redux/features/product/productSlice";
import Spinners from "../../loader/Spinners";
import ProductRating from "../productRating/ProductRating";
import { calculateAverageRating } from "../../../utils";
import Card from "../../card/Card";
import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  saveCartDB,
  selectCartItems,
} from "../../../redux/features/cart/cartSlices";

import styles from "./Product.module.scss";
import CarouselItem from "../../carousel/CarouselItem";
import ProductCarousel from "../../carousel/Carousel";
import { addToWishlist } from "../../../redux/features/auth/authSlice";

const PageHeading = ({ heading, btnText }) => {
  return (
    <>
      <div className="--flex-between">
        <h2 className="--fw-thin">{heading}</h2>
        <Link to="/shop" className="--btn --btn-outline">
          <button className="--btn --btn-active">{btnText}</button>
        </Link>
      </div>
      <div className="--hr"></div>
    </>
  );
};

const ProductDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Fetch product data from Redux store based on the given id
  const [imageIndex, setImageIndex] = useState(0);

  const { product, isLoading } = useSelector((state) => state.product);
  const { products } = useSelector((state) => state.product);
  const cartItems = useSelector(selectCartItems);

  // this shows the details of the product
  const cart = cartItems.find((cart) => cart._id === id);
  // this shows the number of product added to the cart
  const isCartAdded = cartItems.findIndex((cart) => {
    return cart._id === id;
  });

  useEffect(() => {
    dispatch(getProduct(id));
    dispatch(getProducts());
  }, [dispatch, id]);

  const averageRating = calculateAverageRating(product?.ratings);

  const slideLength = product?.image?.length;
  const nextImage = () => {
    setImageIndex(imageIndex === slideLength - 1 ? 0 : imageIndex + 1);
  };

  let slideInterval;

  useEffect(() => {
    if (product?.image?.length > 1) {
      slideInterval = setInterval(nextImage, 3000); // change the delay as needed 1000ms = 1s
      return () => clearInterval(slideInterval);
    }
  }, [imageIndex, slideInterval, product]);

  const addToCart = (product) => {
    dispatch(ADD_TO_CART(product));
    dispatch(
      saveCartDB({ cartItems: JSON.parse(localStorage.getItem("cartItems")) })
    );
  };

  const decreaseCart = () => {
    dispatch(REMOVE_FROM_CART(product));
    dispatch(
      saveCartDB({ cartItems: JSON.parse(localStorage.getItem("cartItems")) })
    );
  };

  const smartLocks = products
    ?.filter((product) => {
      return product?.quantity > 0;
    })
    ?.filter((product) => {
      return product?.category === "Smart Locks";
    })
    ?.filter((product, index) => index < 7);

  const brands = products
    ?.filter((product) => {
      return product?.quantity > 0;
    })
    ?.filter((product) => {
      return product?.brand === "Elilock";
    })
    ?.filter((product, index) => index < 7);

  const relatedProducts = smartLocks.map((item) => (
    <div key={item.id}>
      <CarouselItem
        productName={item.productName}
        url={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        description={item.description}
        product={item}
      />
    </div>
  ));

  const newBrands = brands.map((item) => (
    <div key={item.id}>
      <CarouselItem
        productName={item.productName}
        url={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        description={item.description}
        product={item}
      />
    </div>
  ));

  const buyNow = () => {
    // toast.success("Product added to cart successfully!");
    dispatch(ADD_TO_CART(product));
    navigate("/cart");
  };

  const addToWishList = async (product) => {
    const productData = {
      productId: product._id,
    };
    dispatch(addToWishlist(productData));
  };

  return (
    <>
      <section>
        <div className={`container ${styles.product}`}>
          <h2>Product Details</h2>
          <div className={styles}>
            <Link to={"/shop"}>&larr; Back to Shop</Link>
          </div>
          {isLoading ? (
            <Spinners />
          ) : (
            <>
              <div className={styles.details}>
                <div className={styles.img}>
                  <img
                    src={product?.image[imageIndex]}
                    alt={product?.ProductName}
                    className={styles.pImg}
                  />
                  <div className={styles.smallImg}>
                    {product?.image.map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        alt={product?.ProductName}
                        className={index === imageIndex ? "activeImg" : ""}
                        onClick={() => setImageIndex(index)}
                      />
                    ))}
                  </div>
                </div>
                <div className={styles.content}>
                  <h3>{product?.productName}</h3>
                  <ProductRating
                    averageRating={averageRating}
                    noOfRatings={product?.ratings?.length}
                  />
                  <div className="--underline"></div>
                  <div className={styles.property}>
                    <p>
                      <b> Price: </b>
                    </p>
                    <p className={styles.price}>{`₦${product?.price}`}</p>
                  </div>
                  <div className={styles.property}>
                    <p>
                      <b> SKU: </b>
                    </p>
                    <p>{product?.sku}</p>
                  </div>
                  <div className={styles.property}>
                    <p>
                      <b> Category: </b>
                    </p>
                    <p>{product?.category}</p>
                  </div>
                  <div className={styles.property}>
                    <p>
                      <b> Brand: </b>
                    </p>
                    <p>{product?.brand}</p>
                  </div>
                  <div className={styles.property}>
                    <p>
                      <b> Color: </b>
                    </p>
                    <p>{product?.color}</p>
                  </div>
                  <div className={styles.property}>
                    <p>
                      <b> Quantity Available: </b>
                    </p>
                    <p>{product?.quantity}</p>
                  </div>
                  <div className={styles.property}>
                    <p>
                      <b> Quantity Sold: </b>
                    </p>
                    <p>{product?.sold}</p>
                  </div>
                  <div className={styles.count}>
                    {isCartAdded < 0 ? null : (
                      <>
                        <button
                          className="--btn"
                          onClick={() => decreaseCart(product)}
                        >
                          -
                        </button>
                        <p>
                          <b>{cart.cartQuantity}</b>
                        </p>
                        <button
                          className="--btn"
                          onClick={() => addToCart(product)}
                        >
                          +
                        </button>
                      </>
                    )}
                  </div>
                  <div className="--flex-between">
                    {product?.quantity > 0 ? (
                      <button
                        className="--btn --btn-primary"
                        onClick={() => addToCart(product)}
                      >
                        Add To Cart
                      </button>
                    ) : (
                      <button
                        className="--btn --btn-red"
                        onClick={() =>
                          toast.error("Sorry, Product Out of Stock")
                        }
                      >
                        Out of Stock
                      </button>
                    )}

                    <button
                      className="--btn --btn-success"
                      onClick={() => buyNow()}
                    >
                      Buy Now
                    </button>

                    <FaHeart
                      size={30}
                      color="red"
                      cursor="pointer"
                      onClick={() => addToWishList(product)}
                    />

                    {/* <button className="--btn --btn-danger">
                      Add To Wishlist
                    </button> */}
                  </div>
                  <div className="--underline"></div>
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(product?.description),
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* Review Section */}
          <Card cardClass={styles.card}>
            <h4>Product Reviews</h4>
          </Card>
        </div>
      </section>

      <section>
        <div className="container">
          <PageHeading heading={"Related Products"} btnText={"SHOP NOW"} />
          <ProductCarousel products={relatedProducts} />
        </div>
      </section>

      <section>
        <div className="container">
          <PageHeading heading={"Latest Products"} btnText={"SHOP NOW"} />
          <ProductCarousel products={newBrands} />
        </div>
      </section>
    </>
  );
};

export default ProductDetails;
