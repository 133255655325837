import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Loading from "../../loader/Loading";
import Card from "../../card/Card";
import {
  createCoupon,
  getCoupons,
} from "../../../redux/features/coupon/couponSlice";

const CreateCoupon = () => {
  const [name, setName] = useState("");
  const [discount, setDiscount] = useState(0);
  const [expirationDate, setExpirationDate] = useState(new Date());

  const { isLoading } = useSelector((state) => state.coupon);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCoupons());
  }, [dispatch]);

  const saveCoupon = async (e) => {
    e.preventDefault();

    if (name.length < 3) {
      return toast.error("Coupon must be up to 3 characters");
    }
    if (discount < 1) {
      return toast.error("Discount must be greater than 1");
    }

    const formData = { name, discount, expirationDate };

    await dispatch(createCoupon(formData));
    await dispatch(getCoupons());

    setName("");
    setDiscount(0);
    setExpirationDate(new Date());
  };

 

  return (
    <>
      {isLoading && <Loading />}
      <div className="--mb2">
        <h3>Create Coupon</h3>
        <p>
          Form to <b>Create a Coupon</b>
        </p>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveCoupon}>
            <label>Coupon Name</label>
            <input
              type="text"
              placeholder="Enter coupon name"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <label>Coupon discount</label>
            <input
              type="Number"
              placeholder="Coupon Discount"
              name="discount"
              value={discount}
              onChange={(e) => setDiscount(e.target.value)}
              required
            />
            <label>Expiry Date</label>
            <DatePicker
              selected={expirationDate}
              value={expirationDate}
              onChange={(date) => setExpirationDate(date)}
              required
            />
            <div className="--my">
              <button type="submit" className="--btn --btn-primary">
                Create Coupon
              </button>
              {/* <button type="reset" className="btn btn-secondary">
                Reset
              </button> */}
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateCoupon;
