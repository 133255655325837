import React from "react"
import { Link } from "react-router-dom"

const NotFound =() => {
    return (
        <section style={{ height: "80vh" }}>
            <div className="--center-all">
              <h1>page Not Found!</h1>
              <p>The page you are looking for could not be found!</p>
              <br />
              <Link to={"/"}>
                <button className="--btn btn-primary">Back to Homepage</button>
              </Link>
            </div>
          </section>
    )
}

export default NotFound