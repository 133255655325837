import React from "react";
import ReactDOM from "react-dom";

import styles from "./Spinner.module.scss";

const Spinners = () => {
  return ReactDOM.createPortal(
    <div className={styles.spinner}>
      <div className={styles.spinnerInner}>
        <div className={styles.spinnerCircle} />
        <div className={styles.spinnerCircle} />
        <div className={styles.spinnerCircle} />
        <div className={styles.spinnerCircle} />
      </div>
    </div>,
    document.getElementById("spinner")
  );
};

export default Spinners;
