import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
export const API_URL = `${BACKEND_URL}/api/products/`;

// http request to create products
const createProduct = async (formData) => {
  const response = await axios.post(API_URL, formData);
  return response.data;
};

//  Get products
const getProducts = async () => {
  const response = await axios.get(API_URL);
  return response.data;
};

//  delete product
const deleteProduct = async (id) => {
  const response = await axios.delete(API_URL + id);
  return response.data;
};

// const getProduct = (productId) => async (dispatch) => {
//   console.log('Dispatching getProduct action with productId:', productId);
//   try {
//     const response = await axios.get(API_URL + productId);
//     dispatch({  payload: response.data });
//   } catch (error) {
//     console.error('Error fetching product:', error);
//     dispatch({ payload: error });
//   }
// };

//  get product
const getProduct = async (id) => {
  const response = await axios.get(API_URL + id);
  return response.data;
};

//  update product
const updateProduct = async (id, formData) => {
  const response = await axios.patch(API_URL + id, formData);
  return response.data;
};

//  review product
const reviewProduct = async (id, formData) => {
  const response = await axios.patch(API_URL + `review/${id}`, formData);
  return response.data.message;
};

//  delete review
const deleteReview = async (id, formData) => {
  const response = await axios.patch(API_URL + `deleteReview/${id}`, formData);
  return response.data.message;
};

//  delete review
const updateReview = async (id, formData) => {
  const response = await axios.patch(API_URL + `updateReview/${id}`, formData);
  return response.data.message;
};

const productService = {
  createProduct,
  getProducts,
  deleteProduct,
  getProduct,
  updateProduct,
  reviewProduct,
  deleteReview,
  updateReview,
};

export default productService;
