import React from 'react';
import './DisputeResolution.scss';

const DisputeResolution = () => {
  return (
    <div className="dispute-resolution-container">
      <h1>Dispute Resolution for Elilock Integrated Services</h1>
      <p className="intro">
        At Elilock Integrated Services, we are committed to resolving any disputes or concerns you may have in a fair and timely manner. This Dispute Resolution Policy outlines the steps to follow if you have a dispute with us.
      </p>

      <div className="section">
        <h2>1. Informal Resolution</h2>
        <p>
          If you have any concerns or disputes regarding our products or services, we encourage you to first contact us directly. Most issues can be resolved quickly and amicably through informal communication.
          <br />
          <strong>Contact Information:</strong>
          <br />
          Elilock Integrated Services
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      <div className="section">
        <h2>2. Formal Dispute Resolution</h2>
        <p>
          If informal resolution is not successful, you may initiate a formal dispute resolution process. Please follow these steps:
          <ol>
            <li><strong>Submit a Written Complaint:</strong> Send a detailed written complaint to our email address (<strong>elilockintegratedservices@gmail.com</strong>). Include your contact information, a description of the issue, and any supporting documents.</li>
            <li><strong>Review Process:</strong> Our team will review your complaint and respond within <strong>7 business days</strong> with a proposed resolution or request for additional information.</li>
            <li><strong>Negotiation:</strong> If the issue remains unresolved, we will enter into negotiations with you to reach a mutually acceptable solution.</li>
          </ol>
        </p>
      </div>

      <div className="section">
        <h2>3. Mediation</h2>
        <p>
          If negotiation fails, both parties agree to engage in mediation. Mediation will be conducted by a neutral third-party mediator agreed upon by both parties. The costs of mediation will be shared equally unless otherwise agreed.
        </p>
      </div>

      <div className="section">
        <h2>4. Arbitration</h2>
        <p>
          If mediation does not resolve the dispute, the matter will be referred to binding arbitration. Arbitration will be conducted in accordance with the rules of the Lagos State Arbitration and Conciliation Act. The arbitrator's decision will be final and binding on both parties.
        </p>
      </div>

      <div className="section">
        <h2>5. Governing Law</h2>
        <p>
          This Dispute Resolution Policy is governed by the laws of the Federal Republic of Nigeria. Any disputes arising from this policy shall be resolved in the courts of Lagos State.
        </p>
      </div>

      <div className="section">
        <h2>6. Contact Us</h2>
        <p>
          For any questions or concerns regarding this Dispute Resolution Policy, please contact us at:
          <br />
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      <p className="thank-you">Thank you for choosing Elilock Integrated Services!</p>
    </div>
  );
};

export default DisputeResolution;