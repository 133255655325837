import React from "react";
import { FaFacebook, FaInstagram, FaYoutube, FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import {} from "react-icons/fa";

import logo from "../../assets/elilocklogo.png";

import "./FooterLinks.scss";

const FooterLinks = () => {
  return (
    <>
      <section className="contact-section">
        <div className="container contact">
          <div className="contact-icon">
            <Link to="https://web.facebook.com/profile.php?id=61567897776451">
              <FaFacebook className="i" />
            </Link>
            <Link to="https://tiktok.com/@elilock_ng">
              <FaTiktok className="i" />
            </Link>
            <Link to="https://www.instagram.com/elilock_ng/">
              <FaInstagram className="i" />
            </Link>
            {/* <Link to="https://facebook.com">
              <FaYoutube className="i" />
            </Link> */}
          </div>
          <h2>Need Help?</h2>
          <Link className="btn btn-dark" to="https://wa.me/2349069983442">
            Contact Us
          </Link>
        </div>
      </section>
      <section className="footer-section">
        <div className="container footer">
          <div className="footer-logo">
            <img src={logo} alt="footer logo" />
          </div>

          <div className="footer-menu">
            <p className="link-heading">NEED HELP?</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="https://wa.me/2349069983442">Chat with us</Link>
              </li>              
              <li>
                <Link to="https://wa.me/2349069983442">Contact us</Link>
              </li>
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">ABOUT US</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/about">About us</Link>
              </li>
              <li>
                <Link to="/officialStore">Our offline store</Link>
              </li>
              <li>
                <Link to="/shop">Flash Sales</Link>
              </li>
              <li>
                <Link to="/termsAndConditions">Terms and Conditions</Link>
              </li>
            </ul>
          </div>

          <div className="footer-menu">
            <p className="link-heading">USEFUL LINKS</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/disputeResolution">Dispute Resolution</Link>
              </li>
              <li>
                <Link to="/bulkPurchase">Bulk Purchase</Link>
              </li>
              <li>
                <Link to="/privacyAndPolicy">Privacy & Return Policy</Link>
              </li>
              <li>
                <Link to="/deliveryOptions">Delivery Options</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default FooterLinks;
