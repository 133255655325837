import React from 'react';
import './PrivacyAndRefundPolicy.scss';

const PrivacyAndRefundPolicy = () => {
  return (
    <div className="privacy-refund-container">
      {/* Privacy Policy Section */}
      <h1>Privacy Policy for Elilock Integrated Services</h1>
      <p className="intro">
        At Elilock Integrated Services, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your personal information when you interact with our services.
      </p>

      <div className="section">
        <h2>1. Information We Collect</h2>
        <p>
          We may collect the following types of information:
          <ul>
            <li><strong>Personal Information:</strong> Name, email address, phone number, and billing address.</li>
            <li><strong>Payment Information:</strong> Credit card details, bank account information, or other payment-related data.</li>
            <li><strong>Technical Information:</strong> IP address, browser type, device information, and browsing behaviour.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>2. How We Use Your Information</h2>
        <p>
          We use your information for the following purposes:
          <ul>
            <li>To process orders and provide services.</li>
            <li>To communicate with you about your orders, appointments, or inquiries.</li>
            <li>To improve our website and services.</li>
            <li>To comply with legal obligations.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>3. Data Security</h2>
        <p>
          We implement industry-standard security measures to protect your data from unauthorized access, disclosure, or misuse. However, no method of transmission over the internet is 100% secure.
        </p>
      </div>

      <div className="section">
        <h2>4. Sharing Your Information</h2>
        <p>
          We do not sell or share your personal information with third parties, except:
          <ul>
            <li>To comply with legal requirements.</li>
            <li>To facilitate payment processing.</li>
            <li>With your explicit consent.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>5. Your Rights</h2>
        <p>
          You have the right to:
          <ul>
            <li>Access, update, or delete your personal information.</li>
            <li>Opt-out of marketing communications.</li>
            <li>Withdraw consent for data processing.</li>
          </ul>
          To exercise these rights, please contact us at <strong>elilockintegratedservices@gmail.com</strong>.
        </p>
      </div>

      <div className="section">
        <h2>6. Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Any changes will be posted on our website, and your continued use of our services constitutes acceptance of the updated policy.
        </p>
      </div>

      <div className="section">
        <h2>7. Contact Us</h2>
        <p>
          For questions or concerns about this Privacy Policy, please contact us at:
          <br />
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      {/* Refund Policy Section */}
      <h1>Refund Policy for Elilock Integrated Services</h1>
      <p className="intro">
        At Elilock Integrated Services, we strive to ensure customer satisfaction. This Refund Policy outlines the terms and conditions for requesting refunds.
      </p>

      <div className="section">
        <h2>1. Eligibility for Refunds</h2>
        <p>
          Refunds may be issued under the following circumstances:
          <ul>
            <li>The product is defective or damaged upon delivery.</li>
            <li>The service provided does not meet the agreed-upon specifications.</li>
            <li>The order was cancelled within the allowable time frame which is 48 hours.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>2. Non-Refundable Items</h2>
        <p>
          The following are not eligible for refunds:
          <ul>
            <li>Products or services that have been used or installed and confirmed successful.</li>
            <li>Custom orders or special purchases.</li>
            <li>Services that have already been rendered and confirmed successful.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>3. Refund Process</h2>
        <p>
          To request a refund, please follow these steps:
          <ol>
            <li>Contact us at <strong>elilockintegratedservices@gmail.com</strong> or send us a WhatsApp message on <strong>+2349069983442</strong> within 7 days of receiving the product or service.</li>
            <li>Provide proof of purchase and a detailed explanation of the issue.</li>
            <li>If approved, the refund will be processed within <strong>2 business days</strong> if both the sales and installations were done by our company Elilock Integrated Services, but if only the sales alone were done by us and the installations were done by another company, it will take <strong>7 business days</strong> to complete the refund process.</li>
          </ol>
        </p>
      </div>

      <div className="section">
        <h2>4. Refund Methods</h2>
        <p>
          Refunds will be issued using the original payment method. If this is not possible, an alternative method will be agreed upon.
        </p>
      </div>

      <div className="section">
        <h2>5. Contact Us</h2>
        <p>
          For questions or concerns about this Refund Policy, please contact us at:
          <br />
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      <p className="thank-you">Thank you for choosing Elilock Integrated Services!</p>
    </div>
  );
};

export default PrivacyAndRefundPolicy;