import React from 'react'
import DeliveryOption from '../../components/condtions/DeliveryOptions'
import AboutFooter from '../../components/aboutus/AboutFooter'

const DeliveryOptions = () => {
  return (
    <div>
      <DeliveryOption />
      <AboutFooter />
    </div>
  )
}

export default DeliveryOptions
