import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { validateEmail } from "../../utils";
import Card from "../../components/card/Card";
import Loading from "../../components/loader/Loading";
import { login, RESET_AUTH } from "../../redux/features/auth/authSlice";
// import PasswordInput from "./PasswordInput";

import styles from "./Auth.module.scss";
import { getCartDB, saveCartDB } from "../../redux/features/cart/cartSlices";
const initialState = {
  email: "",
  password: "",
  showPassword: false,
};

const Login = () => {
  const [formData, setFormData] = useState(initialState);
  const { email, password, showPassword } = formData;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, isLoggedIn, isSuccess } = useSelector(
    (state) => state.auth
  );

  const [urlParams] = useSearchParams();

  const redirect = urlParams.get("redirect");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleShowPassword = () => {
    setFormData({ ...formData, showPassword: !showPassword });
  };

  const loginUser = async (e) => {
    e.preventDefault();
    // validation
    if (!email || !password) {
      return toast.error("All fields are required");
    }

    if (!validateEmail(email)) {
      return toast.error("Invalid email format");
    }

    const userData = { email, password };

    await dispatch(login(userData));
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      if (redirect === "cart") {
        dispatch(
          saveCartDB({
            cartItems: JSON.parse(localStorage.getItem("cartItems")),
          })
        );
        return navigate("/cart");
      }
      dispatch(getCartDB());
      navigate("/");
    }
    dispatch(RESET_AUTH());
  }, [isLoggedIn, isSuccess, dispatch, navigate, redirect]);
  return (
    <>
      {isLoading && <Loading />}
      <section className={`container ${styles.auth}`}>
        <div className={styles.img}>
          <img
            src="https://i.ibb.co/qsvVr0q/newlogin.png"
            alt="login"
            width="400"
          />
        </div>
        <Card>
          <div className={styles.form}>
            <h2>Login</h2>
            <form onSubmit={loginUser}>
              <input
                type="text"
                placeholder="Email"
                required
                name="email"
                value={email}
                onChange={handleChange}
              />
            
              <div className={styles.passwordInput}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className={styles.showPasswordBtn}
                  onClick={toggleShowPassword}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>

              <button type="submit" className="--btn --btn-primary --btn-block">
                Login
              </button>
            </form>
            <span className={styles.register}>
              <p>New to ELILOCK? </p>&nbsp;
              <Link to="/register">Create account</Link>
            </span>
          </div>
        </Card>
      </section>
    </>
  );
};

export default Login;


