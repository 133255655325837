import React from "react";

import "./PictureGrid.scss";
const image1 = "https://i.ibb.co/bb7qZ14/inst1.webp";
const image2 = "https://i.ibb.co/TTHST02/inst2.jpg";
const image3 = "https://i.ibb.co/yhXL1pN/inst3.jpg";
const image4 = "https://i.ibb.co/kyV4Vjv/inst4.jpg";

const images = [image1, image2, image3, image4];
const PictureGridTwo = () => {
  return (
    <div className="containers">
      <div className="grids">
        {images.map((image, index) => (
          <div className="grids-item" key={index}>
            <img src={image} alt={`sales ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PictureGridTwo;
