import React, { useState } from "react";
import { IoCloudUpload } from "react-icons/io5";
import { BsFillTrash3Fill } from "react-icons/bs";
import { toast } from "react-toastify";

import Card from "../../card/Card";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;
const url = `https://api.cloudinary.com/v1_1/${cloud_name}/upload`;

const UploadWidget = ({ files, setFiles }) => {
  // state that convert the images to URL
  const [selectedImages, setSelectedImages] = useState([]);
  // state that send the images to cloudinary
  const [images, setImages] = useState([]);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  const addImages = (e) => {
    const selectedFiles = e.target.files;
    const selectedFilesArray = Array.from(selectedFiles);

    // converting the images to URL
    const imagesArray = selectedFilesArray.map((file) => {
      return URL.createObjectURL(file);
    });
    setImages((prevImages) => prevImages.concat(selectedFilesArray));
    setSelectedImages((prevImages) => prevImages.concat(imagesArray));

    // clear the event target
    e.target.value = "";
  };

  const removeImage = (image) => {
    const imageIndex = selectedImages.indexOf(image);
    setSelectedImages(selectedImages.filter((img) => img !== image));
    setImages(images.filter((img, index) => index !== imageIndex));
    URL.revokeObjectURL(image);
  };

  const uploadImages = () => {
    setUploading(true);
    let imageUrls = [];

    const formData = new FormData();
    for (let i = 0; i < images.length; i++) {
      let file = images[i];

      formData.append("file", file);
      formData.append("upload_preset", upload_preset);
      formData.append("folder", "elilockApp");

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          console.log(data);

          imageUrls.push(data.secure_url);
          setProgress(imageUrls.length);

          // check the process of the upload
          if (imageUrls.length === images.length) {
            setFiles((prevFiles) => prevFiles.concat(imageUrls));
            setUploading(false);
            console.log(files);
            toast.success("Upload Successful");

            setImages([]);
            setSelectedImages([]);
            setProgress(0);
          }
        })
        .catch((error) => {
          setUploading(false);
          toast.error(error.message);
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Card cardClass={"formcard group"}>
        <label className="uploadWidget">
          <IoCloudUpload size={35} color={"green"} />
          <br />
          <span>Click to upload up to 5 images</span>
          <input
            type="file"
            name="images"
            onChange={addImages}
            multiple
            accept="image/png, image/jpeg, image/webp"
            // accept="image/*"
          />
        </label>
        <br />
        {selectedImages.length > 0 &&
          (selectedImages.length > 5 ? (
            <p className="error">
              Maximum 5 images can be uploaded
              <br />
              <span>
                Please remove the extra <b>{selectedImages.length - 5}</b>
              </span>
            </p>
          ) : (
            <div className="--center-all">
              <button
                className="--btn --btn-danger --btn-large"
                disabled={uploading}
                onClick={uploadImages}
              >
                {uploading
                  ? `Uploading ${progress} of ${images.length}`
                  : `Upload ${images.length} Image(s)`}
              </button>
            </div>
          ))}
        {/* view selected Images */}
        <div className={selectedImages.length > 0 ? "images" : ""}>
          {selectedImages !== 0 &&
            selectedImages.map((image, index) => {
              return (
                <div key={image} className="image">
                  <img src={image} alt={`image-${index}`} width={200} />
                  <button className="--btn" onClick={() => removeImage(image)}>
                    <BsFillTrash3Fill size={25} />
                  </button>
                  <p>{index + 1}</p>
                </div>
              );
            })}
        </div>
      </Card>
    </div>
  );
};

export default UploadWidget;
