import React, { useEffect} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import { Toaster } from "sonner";
// import { Toaster } from "react-hot-toast";

import Home from "./pages/home/Home";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Login from "./pages/auth/Login";
import Register from "./pages/auth/Register";
import Profile from "./pages/profile/Profile";
import { useDispatch, useSelector } from "react-redux";
import { getUser, loginStatus } from "./redux/features/auth/authSlice";
import Admin from "./pages/admin/Admin";
import AdminOnlyRoute from "./components/hiddenLink/AdminOnlyRoute";
import NotFound from "./pages/404/NotFound";
import Product from "./pages/shop/Product";
import { getProducts } from "./redux/features/product/productSlice";
import ProductDetails from "./components/product/productDetails/ProductDetails";
import Cart from "./pages/cart/Cart";
import CheckoutDetails from "./pages/checkout/CheckoutDetails";
import Checkout from "./pages/checkout/Checkout";
import PayWithFlutterwave from "./pages/checkout/PayWithFlutterwave";
import CheckoutSuccess from "./pages/checkout/CheckoutSuccess";
import OrderDetails from "./pages/order/OrderDetails";
import OrderHistory from "./pages/order/OrderHistory";
import Wishlist from "./pages/wishlist/Wishlist";
import ReviewProduct from "./pages/reviewProduct/ReviewProduct";
import OurServices from "./components/ourServices/OurServices";
import Contact from "./pages/contact/Contact";
import About from "./pages/about/About";
import TermsAndCondition from "./pages/Terms/TermsAndCondition";
import Privacy from "./pages/Terms/Privacy";
import DisputeResolutions from "./pages/Terms/DisputeResolutions";
import BulkPurchase from "./pages/Terms/BulkPurchase";
import DeliveryOptions from "./pages/Terms/DeliveryOptions";
import OfficialStore from "./pages/Terms/OfficialStore";

const App = () => {
  axios.defaults.withCredentials = true;

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loginStatus());
  }, [dispatch]);

  useEffect(() => {
    if (isLoggedIn && user === null) {
      dispatch(getUser());
    }
  }, [dispatch, isLoggedIn, user]);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);


  return (
    <>
      <BrowserRouter>
        <ToastContainer
          position="top-center"
          autoClose="2000"
          theme="colored"
          hideProgressBar={true}
          newestOnTop={true}
        />
        {/* <Toaster position="top-center" richColors /> */}
        {/* <Toaster postion="top-center" /> */}
        
        <Header />        

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/profile" element={<Profile />} />

          <Route path="/shop" element={<Product />} />
          <Route path="/product-details/:id" element={<ProductDetails />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/about" element={<About />} />
          <Route path="/termsAndConditions" element={<TermsAndCondition />} />
          <Route path="/privacyAndPolicy" element={<Privacy />} />
          <Route path="/disputeResolution" element={<DisputeResolutions />} />
          <Route path="/bulkPurchase" element={<BulkPurchase />} />
          <Route path="/deliveryOptions" element={<DeliveryOptions />} />
          <Route path="/officialStore" element={<OfficialStore />} />

          <Route path="/checkout-success" element={<CheckoutSuccess />} />
          <Route path="/order-history" element={<OrderHistory />} />
          <Route path="/order-details/:id" element={<OrderDetails />} />

          <Route path="/checkout-details" element={<CheckoutDetails />} />
          <Route path="/checkout-stripe" element={<Checkout />} />

          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/review-product/:id" element={<ReviewProduct />} />
          <Route
            path="/checkout-flutterwave"
            element={<PayWithFlutterwave />}
          />

          {/* Admin Only Routes */}
          <Route
            path="/admin/*"
            element={
              <AdminOnlyRoute>
                <Admin />
              </AdminOnlyRoute>
            }
          />
          <Route path="*" element={<NotFound />} />
        </Routes>

        {/* <Routes>
          <Route path="*" element={<Navigate to="/" />} />
        </Routes> */}

        

        <Footer />
      </BrowserRouter>
    </>
  );
};

export default App;
