import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./ProductCategory.scss";

const categories = [
  {
    id: 1,
    title: "Smart Locks",
    image: "https://i.ibb.co/NZyr6XH/smartlocks.jpg",
  },
  {
    id: 2,
    title: "Mortice Locks",
    image: "https://i.ibb.co/YWx7Rbh/smrt13.jpg",
  },
  {
    id: 3,
    title: "Door Ironery",
    image: "https://i.ibb.co/WB5JzMZ/smrt12.jpg",
  },
  {
    id: 4,
    title: "Cylinders",
    image: "https://i.ibb.co/KVwV4kZ/smrt14.jpg",
  },
];

const Category = ({ title, image }) => {
  return (
    <div className="category">
      <h3>{title}</h3>
      <img src={image} alt={title} />
      <Link to="/shop" className="--btn --btn-outline">
        <button className="--btn --btn-active">SHOP NOW</button>
      </Link>
    </div>
  );
};

const ProductCategory = () => {
  return (
    <div className="categories">
      {categories.map((cat) => {
        return (
          <div key={cat.id} className="--flex-center">
            <Category title={cat.title} image={cat.image} />
          </div>
        );
      })}
    </div>
  );
};

export default ProductCategory;
