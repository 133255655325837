import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

// import { toast } from "sonner";
// import toast from "react-hot-toast";

import Card from "../../components/card/Card";
import { validateEmail } from "../../utils";
import { register, RESET_AUTH } from "../../redux/features/auth/authSlice";
import Loading from "../../components/loader/Loading";

import styles from "./Auth.module.scss";

const initialState = {
  name: "",
  email: "",
  password: "",
  confirmPassword: "",
  showPassword: false,
  showConfirmPassword: false,
};

const Register = () => {
  const [formData, setFormData] = useState(initialState);
  const {
    name,
    email,
    password,
    confirmPassword,
    showPassword,
    showConfirmPassword,
  } = formData;
  const { isLoading, isLoggedIn, isSuccess } = useSelector(
    (state) => state.auth
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const toggleShowPassword = () => {
    setFormData({ ...formData, showPassword: !showPassword });
  };

  const toggleShowConfirmPassword = () => {
    setFormData({ ...formData, showConfirmPassword: !showConfirmPassword });
  };

  const registerUser = async (e) => {
    e.preventDefault();
    // validation
    if (!email || !password) {
      return toast.error("All fields are required");
    }
    if (password !== confirmPassword) {
      return toast.error("Passwords do not match");
    }
    if (password.length < 8) {
      return toast.error("password must be greater than 8 characters");
    }
    if (!validateEmail(email)) {
      return toast.error("Invalid email format");
    }
    const userData = { name, password, email };

    await dispatch(register(userData));
  };

  useEffect(() => {
    if (isSuccess && isLoggedIn) {
      navigate("/");
    }

    dispatch(RESET_AUTH());
  }, [isLoggedIn, isSuccess, dispatch, navigate]);

  return (
    <>
      {isLoading && <Loading />}
      <section className={`container ${styles.auth}`}>
        <Card>
          <div className={styles.form}>
            <h2>Register</h2>
            <form onSubmit={registerUser}>
              <input
                type="text"
                placeholder="Name"
                required
                name="name"
                value={name}
                onChange={handleChange}
              />
              <input
                type="text"
                placeholder="Email"
                required
                name="email"
                value={email}
                onChange={handleChange}
              />

              <div className={styles.passwordInput}>
                <input
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  required
                  name="password"
                  value={password}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className={styles.showPasswordBtn}
                  onClick={toggleShowPassword}
                >
                  {showPassword ? "Hide" : "Show"}
                </button>
              </div>

              <div className={styles.passwordInput}>
                <input
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  required
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                />
                <button
                  type="button"
                  className={styles.showPasswordBtn}
                  onClick={toggleShowConfirmPassword}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </button>
              </div>

              <button type="submit" className="--btn --btn-primary --btn-block">
                Register
              </button>
            </form>
            <span className={styles.register}>
              <p>Already a user?</p> &nbsp;
              <Link to="/login">Login</Link>
            </span>
          </div>
        </Card>
        <div className={styles.img}>
          <img
            src="https://i.ibb.co/x1b62HQ/newregister.gif"
            alt="register"
            width={400}
          />
        </div>
      </section>
    </>
  );
};

export default Register;
