import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  createCategory,
  getCategories,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";
import Card from "../../card/Card";
import Loading from "../../loader/Loading";

const CreateCategory = () => {
  const [name, setName] = useState("");
  const { isLoading } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  const saveCategory = async (e) => {
    e.preventDefault();

    if (name.length < 3) {
      return toast.error("Category name must be up to 3 characters");
    }
    const formData = {
      name,
    };
    await dispatch(createCategory(formData));
    await dispatch(getCategories());
    setName("");
  };

  return (
    <>
      {isLoading && <Loading />}
      <div className="--mb2">
        <h3>Create Category</h3>
        <p>
          Form to <b>Create a Category</b>
        </p>
        <Card cardClass={"card"}>
          <br />
          <form onSubmit={saveCategory}>
            <label>Category Name</label>
            <input
              type="text"
              placeholder="Enter category name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <div className="--my">
              <button type="submit" className="--btn --btn-primary">
                Create Category
              </button>
              {/* <button type="reset" className="btn btn-secondary">
              Reset
            </button> */}
            </div>
          </form>
        </Card>
      </div>
    </>
  );
};

export default CreateCategory;
