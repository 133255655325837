import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../../redux/features/auth/authSlice";

const AdminOnlyRoute = ({ children }) => {
  const user = useSelector(selectUser);
  

  if (user && user.role === "admin") {
    return children
  }
  return (
    <section style={{ height: "80vh" }}>
      <div className="container">
        <h1>You are not authorized to access this page.</h1>
        <p>Please log in as an admin to view this content.</p>
        <br />
        <Link to={"/"}>
          <button className="--btn btn-primary">Back to Homepage</button>
        </Link>
      </div>
    </section>
  );
};

export const AdminOnlyLink =({children}) => {
  const user = useSelector(selectUser)

  if(user && user.role === "admin") {
    return <>{children}</>
  }
  return null;

 
}

export default AdminOnlyRoute;
