import { useRef } from "react";
import emailjs from "@emailjs/browser";

import { IoLocationSharp } from "react-icons/io5";
import { MdEmail } from "react-icons/md";
import { FaPhoneVolume, FaXTwitter } from "react-icons/fa6";
import { BiLogoTiktok } from "react-icons/bi";
import { FaWhatsappSquare } from "react-icons/fa";

import "./ContactUs.css";

const ContactUs = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("service_o8r348k", "template_c6bgzbn", form.current, {
        publicKey: "45NKfyYIgYynyC598",
      })
      .then(
        () => {
          alert("Message Sent Successfully");
          console.log("SUCCESS!");
        },
        (error) => {
          alert("Error Sending message");
          console.log("FAILED...", error.text);
        }
      );
    e.target.reset();
  };
  return (
    <div className="contactUs-container">
      <div className="contactStyle">
        <div className="title">
          <h2>Get in Touch With Us</h2>
        </div>
        <div className="boxes">
          {/* Form */}
          <div className="contacts forms">
            <h3>Your Message Here</h3>
            <form ref={form} onSubmit={sendEmail}>
              <div className="formBoxes">
                <div className="row1">
                  <div className="inputBoxes">
                    <span>First Name</span>
                    <input
                      type="text"
                      placeholder="Your First Name"
                      name="user_first"
                      required
                    />
                  </div>
                  <div className="inputBoxes">
                    <span>Last Name</span>
                    <input
                      type="text"
                      placeholder="Your Last Name"
                      name="user_last"
                      required
                    />
                  </div>
                </div>
                <div className="row1">
                  <div className="inputBoxes">
                    <span>Email Address</span>
                    <input
                      type="email"
                      placeholder="your email address here"
                      name="user_email"
                      required
                    />
                  </div>
                  <div className="inputBoxes">
                    <span>Phone Number</span>
                    <input
                      type="text"
                      placeholder="your phone number here"
                      name="user_number"
                      required
                    />
                  </div>
                </div>
                <div className="inputBoxes">
                  <span>Subject</span>
                  <input
                    type="text"
                    placeholder="Subject"
                    name="subject"
                    required
                  />
                </div>
                <div className="row2">
                  <div className="inputBoxes">
                    <span>Message</span>
                    <textarea
                      name="message"
                      placeholder="Write something"
                      required
                    ></textarea>
                  </div>
                </div>
                <div className="row2">
                  <div className="inputBoxes">
                    <button type="submit" className="--btn --btn-primary">
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {/* info bos */}
          <div className="contacts infos">
            <h3>Contact Information</h3>
            <div className="infoBoxes">
              <div>
                <span>
                  <IoLocationSharp name="location" />
                </span>
                <p>
                  24 Boski Complex, Juihua Str Lekki County Homes, Ikota Lekki,
                  Lagos Nigeria.
                </p>
              </div>
              <div>
                <span>
                  <MdEmail name="mail" />
                </span>
                <a href="mailto:elilockintegratedservices@gmail.com">
                  elilockintegratedservices@gmail.com
                </a>
              </div>
              <div>
                <span>
                  <FaPhoneVolume name="phone" />
                </span>
                <a href="tel:+234906998332">+234 906 998 3442</a>
              </div>
              {/* social media */}
              <ul className="smi">
                <li>
                  <a href="https://www.instagram.com/elilock_ng/"></a>
                </li>
                <li>
                  <a href="https://www.tiktok.com/ng_elilock/">
                    <BiLogoTiktok />
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/">
                    <FaXTwitter />
                  </a>
                </li>
                <li>
                  <a href="https://wa.me/2349069983442/">
                    <FaWhatsappSquare />
                  </a>
                </li>
              </ul>
            </div>
          </div>
          {/* Map */}
          <div className="contacts maps">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d294.66846766174007!2d3.5521389507849688!3d6.449143508161624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sng!4v1720304971584!5m2!1sen!2sng"
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
              title="responsive Google Map"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
