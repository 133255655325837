import React from 'react';
import './TermsAndConditions.scss';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions for Elilock Integrated Services</h1>
      <p className="intro">
        Welcome to Elilock Integrated Services! These Terms and Conditions govern your use of our services, including the sale, servicing, and installation of smart locks, mortise locks, ironeries, and cylinders. By engaging with our services, you agree to comply with and be bound by these terms. Please read them carefully.
      </p>

      <div className="section">
        <h2>1. Scope of Services</h2>
        <p>
          Elilock Integrated Services provides the following:
          <ul>
            <li>Sales of smart locks, mortise locks, ironeries, and cylinders.</li>
            <li>Servicing and maintenance of locks and related hardware.</li>
            <li>Installation of locks and security systems.</li>
          </ul>
          All services are subject to availability and may vary based on location and product specifications.
        </p>
      </div>

      <div className="section">
        <h2>2. Orders and Payments</h2>
        <p>
          <strong>Orders:</strong> All orders are subject to acceptance and availability. We reserve the right to refuse or cancel any order at our discretion.
          <br />
          <strong>Payment:</strong> Payment must be made in full before the commencement of services, unless otherwise agreed in writing. We accept payment via bank transfer, cash, or other agreed-upon methods.
          <br />
          <strong>Pricing:</strong> Prices are subject to change without notice. However, once an order is confirmed, the price will remain fixed unless additional services are requested.
        </p>
      </div>

      <div className="section">
        <h2>3. Installation and Servicing</h2>
        <p>
          <strong>Scheduling:</strong> Installation and servicing appointments will be scheduled based on mutual availability. We will make every effort to arrive on time but cannot be held liable for delays due to unforeseen circumstances.
          <br />
          <strong>Customer Responsibility:</strong> Customers are responsible for ensuring that the installation area is accessible and prepared for our technicians. Any delays caused by inadequate preparation may result in additional charges.
          <br />
          <strong>Warranty:</strong> Installation and servicing work is covered by a limited warranty for a period of [insert warranty period, e.g., 6 months] from the date of service, provided that the product is used as intended and maintained properly.
        </p>
      </div>

      <div className="section">
        <h2>4. Product Warranty</h2>
        <p>
          All products sold by Elilock Integrated Services come with a manufacturer’s warranty, the terms of which will be provided at the time of purchase.
          <br />
          The warranty does not cover damage caused by misuse, unauthorized repairs, or natural wear and tear.
        </p>
      </div>

      <div className="section">
        <h2>5. Cancellations and Refunds</h2>
        <p>
          <strong>Cancellations:</strong> Customers may cancel orders or services up to 48 hours before the scheduled appointment. Cancellations made after this period may incur a fee.
          <br />
          <strong>Refunds:</strong> Refunds will be issued in accordance with our refund policy, which will be provided upon request, or you can check out our privacy and refund policy section on our website. Refunds are subject to inspection of returned products and may take up to 7 business days only when the sales were done with our company Elilock Integrated Services while the installation services were done by another company or technicians, and 2 business days if the sales and installations was done by our company Elilock Integrated Services.
        </p>
      </div>

      <div className="section">
        <h2>6. Liability</h2>
        <p>
          Elilock Integrated Services shall not be liable for any indirect, incidental, or consequential damages arising from the use or inability to use our products or services.
          <br />
          Our liability is limited to the cost of the product or service provided.
        </p>
      </div>

      <div className="section">
        <h2>7. Force Majeure</h2>
        <p>
          Elilock Integrated Services shall not be held liable for any failure or delay in performing our obligations due to events beyond our control, including but not limited to natural disasters, strikes, or government restrictions.
        </p>
      </div>

      <div className="section">
        <h2>8. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of the Federal Republic of Nigeria. Any disputes arising from these terms shall be resolved through negotiation, mediation, or in the courts of Lagos State.
        </p>
      </div>

      <div className="section">
        <h2>9. Amendments</h2>
        <p>
          Elilock Integrated Services reserves the right to amend these Terms and Conditions at any time. Customers will be notified of any significant changes, and continued use of our services constitutes acceptance of the revised terms.
        </p>
      </div>

      <div className="section">
        <h2>10. Contact Information</h2>
        <p>
          For any questions or concerns regarding these Terms and Conditions, please contact us at:
          <br />
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      <p className="acknowledgement">
        By engaging with Elilock Integrated Services, you acknowledge that you have read, understood, and agreed to these Terms and Conditions.
        <br />
        <strong>Effective Date:</strong> 5 – March - 2025
      </p>

      <p className="thank-you">Thank you for choosing Elilock Integrated Services!</p>
    </div>
  );
};

export default TermsAndConditions;