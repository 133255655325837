import React from "react";
import DisputeResolution from "../../components/condtions/DisputeResolution";
import FooterLinks from "../../components/footer/FooterLinks";

const DisputeResolutions = () => {
  return (
    <div>
      <DisputeResolution />
      <FooterLinks />
    </div>
  );
};

export default DisputeResolutions;
