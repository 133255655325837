import React from 'react';
import './StoreLocation.scss';

const StoreLocation = () => {
  return (
    <div className="store-location-container">
      <h1>Official Store Location</h1>
      <p className="intro">
        Visit our official store at the address below. We look forward to welcoming you!
      </p>

      <div className="section">
        <h2>Store Address</h2>
        <p>
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
        </p>
      </div>

      <div className="section">
        <h2>Opening Hours</h2>
        <p>
          <strong>Monday - Saturday:</strong> 7:00 AM - 9:00 PM
          <br />
          <strong>Sunday:</strong> 10:00 AM - 6:00 PM
        </p>
      </div>

      <div className="section">
        <h2>Contact Information</h2>
        <p>
          <strong>Phone:</strong> +2349069983442
          <br />
          <strong>Email:</strong> elilockintegratedservices@gmail.com
        </p>
      </div>

      <div className="section">
        <h2>Location on Map</h2>
        <div className="map-container">
          <iframe
            title="Elilock Integrated Services Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.123456789012!2d3.123456789012345!3d6.123456789012345!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zNsKwMDcnMjQuNCJOIDPCsDA3JzQ1LjYiRQ!5e0!3m2!1sen!2sng!4v1234567890123!5m2!1sen!2sng"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <p className="thank-you">Thank you for choosing Elilock Integrated Services!</p>
    </div>
  );
};

export default StoreLocation;