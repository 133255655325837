import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { BsTrash } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import StarRating from "react-star-ratings";

import Loading from "../../components/loader/Loading";
import Card from "../../components/card/Card";

import {
  deleteReview,
  getProduct,
  reviewProduct,
} from "../../redux/features/product/productSlice";

import styles from "./ReviewProduct.module.scss";

const ReviewProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const [rate, setRate] = useState(0);
  const [review, setReview] = useState("");
  const [userReview, setUserReview] = useState([]);
  const [isEditing, setIsEditing] = useState(false);

  const { isLoading, product } = useSelector((state) => state.product);
  const { user } = useSelector((state) => state.auth);

  const changeStar = (newRating, productName) => {
    setRate(newRating);
  };
  const submitReview = async (e) => {
    e.preventDefault();
    const today = new Date();
    const date = today.toDateString();

    if (rate === 0 || review === "") {
      return toast.error("Please give a rating and a review");
    }
    const formData = {
      star: rate,
      review,
      reviewDate: date,
    };
    dispatch(reviewProduct({ id, formData }));
    setTimeout(() => {
      navigate(-1);
    }, 2000);
  };

  const editReview = async (e) => {
    e.preventDefault();
    setIsEditing(true);
  };

  useEffect(() => {
    dispatch(getProduct(id));
  }, [dispatch, id]);

  // useEffect(() => {
  //   if (product) {
  //     console.log(product);
  //     const reviewed = product?.ratings.filter((rev) => {
  //       return rev.userID === user._id;
  //     });
  //     setUserReview(reviewed);
  //   }
  // }, [product, user]);

  useEffect(() => {
    console.log("product:", product);
    console.log("user:", user);
    console.log("product?.ratings:", product?.ratings);
    console.log("user._id:", user?._id);

    if (product?.ratings && user && user?._id) {
      const reviewed = product?.ratings.filter((rev) => {
        return rev.userID === user?._id;
      });
      setUserReview(reviewed);
    } else {
      console.log("Not enough data to filter reviews");
    }

    // const reviewed =
    //   product &&
    //   product?.ratings.filter((rev) => {
    //     return rev.userID === user._id;
    //   });
    // setUserReview(reviewed);
  }, [product, user]);

  const startEdit = async () => {};
  useEffect(() => {
    const reviewed =
      product &&
      product?.ratings.filter((rev) => {
        return rev.userID === user._id;
      });
    setUserReview(reviewed);
  }, [product, user]);
  
  const delReview = async () => {
    const formData = {
      userID: user?._id,
    };
    dispatch(deleteReview({ id, formData }));
    navigate(-1);
    toast.success("Review deleted successfully");
  };

  return (
    <section className={styles.rvh}>
      <div className="container review">
        <h2>Review Product</h2>
        {isLoading && product === null ? (
          <Loading />
        ) : (
          <>
            <div className="product-details"></div>
            <p>
              <b>Product Name:</b> {product?.productName}
            </p>
            <img
              src={product?.image[0]}
              alt="product-image"
              style={{ width: "100px" }}
            />
          </>
        )}
        {userReview?.length > 0 && !isEditing ? (
          <Card cardClass={"card"}>
            <h3>Product Reviews</h3>
            {userReview?.map((item, index) => {
              const { star, review, reviewDate, name } = item;

              return (
                <div key={index} className="review --flex-between --p">
                  <div>
                    <StarRating
                      rating={star}
                      numberOfStars={5}
                      starDimension="20px"
                      starRatedColor="#F6B01E"
                      // starHoverColor="#F6B01E"
                      starSpacing="2px"
                      changeRating={changeStar}
                      editing={false}
                      isSelectable={true}
                    />
                    <p>{review}</p>
                    <span>
                      <b>Review Date: {reviewDate}</b>
                    </span>
                    <br />
                    <span>
                      <b>By: {name}</b>
                    </span>
                  </div>
                  <div>
                    <BsTrash size={25} color="red" onClick={delReview} />
                    <FaEdit
                      size={25}
                      color="green"
                      onClick={() => startEdit()}
                    />
                  </div>
                </div>
              );
            })}
          </Card>
        ) : (
          <Card cardClass={"card --width-500px --p"}>
            <form style={{ width: "480px" }}>
              <label> Star Rating</label>
              <StarRating
                rating={rate}
                numberOfStars={5}
                starDimension="20px"
                starRatedColor="#F6B01E"
                starHoverColor="#F6B01E"
                starSpacing="2px"
                changeRating={changeStar}
                editing={true}
                isSelectable={true}
              />
              <label>Review</label>
              <textarea
                value={review}
                onChange={(e) => setReview(e.target.value)}
                placeholder="Write your review here..."
                required
                cols="30"
                rows="10"
              />
              {!isEditing ? (
                <button
                  onClick={(e) => submitReview(e)}
                  className="--btn --btn-primary"
                >
                  Submit Review
                </button>
              ) : (
                <div className="--flex-start">
                  <button
                    onClick={(e) => editReview(e)}
                    className="--btn --btn-primary"
                  >
                    Update Review
                  </button>
                  <button onClick={() => setIsEditing(false)} className="--btn">
                    Cancel
                  </button>
                </div>
              )}
            </form>
          </Card>
        )}
      </div>
    </section>
  );
};

export default ReviewProduct;
