import React from "react";
import { NavLink } from "react-router-dom";

import "./PageMenu.scss";
const PageMenu = () => {
  return (
    <div>
      <nav className="--bg-purple --p --mb">
        <ul className="home-links">
          <li>
            <NavLink className="spcr" to="/profile">
              Profile
            </NavLink>
            {/* <NavLink className="spcr" to="/wallet">
              My Wallet
            </NavLink>
            <NavLink className="spcr" to="/wishlist">
              WishList
            </NavLink> */}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default PageMenu;
