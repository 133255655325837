import React from "react";

import "./PictureGrid.scss";

const image1 = "https://i.ibb.co/3fS418T/sales-1.jpg";
const image2 = "https://i.ibb.co/Y048QVF/sales-2.jpg";
const image3 = "https://i.ibb.co/gM18YDw/sales3.png";
const image4 = "https://i.ibb.co/sCx95ZT/sales4.jpg";

const images = [image1, image2, image3, image4];
const PictureGrid = () => {
  return (
    <div className="containers">
      <div className="grids">
        {images.map((image, index) => (
          <div className="grids-item" key={index}>
            <img src={image} alt={`sales ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PictureGrid;
