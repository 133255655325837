import React from "react";
import { Link } from "react-router-dom";

import PictureGrid from "../pictures/PictureGrid";
import PictureGridOne from "../pictures/PictureGridOne";
import PictureGridTwo from "../pictures/PictureGridTwo";
import FooterLink from "../footer/FooterLink";

import "./ServicesOne.css";

const History = () => {
  return (
    <>
      <section className="container history-hero">
        <div className="history-heading">
          <h1>Our Services</h1>
        </div>
        <div className="history-container">
          <div className="history-hero-content">
            <h2>SALES</h2>
            <p>
              At EliLock Integrated Services, we pride ourselves on being the
              premier provider of smart locks, mortise locks, door ironery, and
              door hardware. Our commitment to excellence sets us apart in the
              industry. We are unparalleled in offering the most competitive
              prices in the market. Our prices are so attractive that we set the
              benchmark for others to follow.
              <br />
              <br />
              Despite our affordable prices, we do not compromise on quality.
              Each product we offer undergoes rigorous quality checks to ensure
              it meets our high standards <br /> <br />
              We guarantee that all our products are genuine and original,
              sourced from trusted manufacturers. Our team of experts is
              dedicated to providing exceptional customer service, ensuring that
              your experience with us is seamless and satisfying.
            </p>
            {/* <button className="history-cta">CONTACT US</button> */}
          </div>
          <div className="history-image">
            <img
              src="https://i.ibb.co/8jV3bRp/ELILOCK-SALES-TEAM.png"
              alt="elilock"
            />
          </div>
        </div>
        <PictureGrid />
        <div className="history-container">
          <div className="history-image">
            <img
              src="https://i.ibb.co/WKP5G4V/ELILOCK-SALES-TEAM.png"
              alt="elilock"
            />
          </div>
          <div className="history-hero-content">
            <h2>SERVICING</h2>
            <p>
              Are you looking forward to fixing your present smart locks? Did
              you notice some misbehavior in your smart locks? Our servicing
              team is here to help you. We understand how crucial it is to have
              fully functional and secure smart locks in your home or office.
              <br />
              <br />
              We offer the best servicing for your smart locks, ensuring they
              operate at their peak performance. Our team of experts is
              dedicated to providing exceptional customer service, ensuring that
              your experience with us is seamless and satisfying. Whether it's a
              minor glitch or a significant malfunction, our skilled technicians
              are equipped with the latest tools and knowledge to diagnose and
              resolve any issues efficiently.
              <br />
              <br />
              In addition to repairs, we also provide regular maintenance
              services to prevent future problems and extend the lifespan of
              your smart locks. Our comprehensive maintenance checks include
              software updates, battery replacements, and mechanical adjustments
              to keep your smart locks running smoothly.
            </p>
          </div>
        </div>
        <PictureGridOne />
        <div className="history-container">
          <div className="history-hero-content">
            <h2>INSTALLATIONS</h2>
            <p>
              Our comprehensive smart lock solutions are backed by a team of
              highly skilled experts who specialize in efficient and reliable
              installations. This dedicated team is committed to delivering
              exceptional customer service, ensuring a seamless and satisfying
              experience for our valued clients.
              <br />
              <br />
              From initial consultation to final installation, our experts work
              closely with clients to understand their unique needs and
              preferences. We take pride in our meticulous attention to detail,
              guaranteeing that every installation meets the highest standards
              of quality and functionality.
              <br />
              <br />
              In the unlikely event of an issue, our responsive support team is
              readily available to address any concerns. Our skilled technicians
              are equipped with cutting-edge tools and extensive knowledge to
              diagnose and resolve problems efficiently, minimizing downtime and
              ensuring uninterrupted functionality.
              <br />
              <br />
              At Elilock Integrated Services, we recognize that exceptional
              service is just as important as innovative products. That's why
              we're dedicated to providing a holistic experience that exceeds
              our clients' expectations. With our expert installation services,
              you can trust that your smart lock system will be installed
              correctly, functions flawlessly, and provides unparalleled
              security and convenience.
            </p>
          </div>
        </div>
        <PictureGridTwo />
        <div className="history-container">
          <div>
            <Link to="/contact" className="--btn --btn-outline">
              <button className="--btn --btn-active">Contact Us</button>
            </Link>
          </div>
          <div>
            <Link to="/shop" className="--btn --btn-outline">
              <button className="--btn --btn-active">Go to Shop</button>
            </Link>
          </div>
        </div>
      </section>
      <FooterLink />
    </>
  );
};

export default History;
