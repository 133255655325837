import React from 'react'
import StoreLocation from '../../components/condtions/Store'
import AboutFooter from '../../components/aboutus/AboutFooter'

const OfficialStore = () => {
  return (
    <div>
      <StoreLocation />
      <AboutFooter />
    </div>
  )
}

export default OfficialStore
