import React from "react";


import PayCard from "../../components/aboutus/PayCard";
import Values from "../../components/aboutus/Values";
import History from "../../components/aboutus/History";
import HotDeals from "../../components/aboutus/HotDeals";
import More from "../../components/aboutus/More";

import "./About.css";
import "./About1.css";
import AboutFooter from "../../components/aboutus/AboutFooter";
import Testimonials from "../../components/aboutus/Testimonials";

export default function About() {
  return (
    <div className="App">
      {/* <Hero /> */}
      <div className="about-section-bg paddings">
        <History />
        <Values />
        <More />
        <div className="flexColCenter">
          <span className="orangeText core-values">OUR VISION</span>
          <span className="primaryText core1-values">
            Here Below is our vision statement.
          </span>
          <span className="secondaryText text-values">
            Harnessing cutting-edge innovation and technology to ensure
            comprehensive protection
          </span>
        </div>

        <div className="flexColCenter">
          <span className="orangeText core-values">OUR MISION</span>
          <span className="primaryText core1-values">
            Here Below is Our Mission Statement
          </span>
          <span className="secondaryText text-values">
            To secure, protect and inspire people around the globe through the
            power of smart locks reflecting the <br />
            iconic brands, creative minds and innovative technologies that makes
            ours the world's premium protection company
          </span>
        </div>
      </div>
      <div className="about1-section-bg">
        <div className="paddings">{/* <ProductDetailed /> */}</div>

        <HotDeals />
        <Testimonials />
        <PayCard />
      </div>
      <AboutFooter />
    </div>
  );
}
