import axios from "axios";

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

export const API_URL = `${BACKEND_URL}/api/users/`;

// Register User
const register = async (userData) => {
  // link the api route
  const response = await axios.post(API_URL + "register", userData);
  // return the data you got from backend to the frontend
  return response.data;
};

// login
const login = async (userData) => {
  // link the api route
  const response = await axios.post(API_URL + "login", userData);
  // return the data you got from backend to the frontend
  return response.data;
};

// logout user
const logout = async (userData) => {
  // link the api route
  const response = await axios.get(API_URL + "logout");
  // return the data you got from backend to the frontend
  return response.data.message;
};

// Get Login Status
const loginStatus = async () => {
  const response = await axios.get(API_URL + "loginstatus");

  return response.data;
};

// Get User
const getUser = async () => {
  const response = await axios.get(API_URL + "getuser");

  return response.data;
};

// Update User Profile
const updateUser = async (userData) => {
  const response = await axios.patch(API_URL + "updateUser", userData);

  return response.data;
};

// Update User photo
const updatePhoto = async (userData) => {
  const response = await axios.patch(API_URL + "updatePhoto", userData);

  return response.data;
};

// Add to wishlist
const addToWishlist = async (productData) => {
  const response = await axios.post(API_URL + "addToWishlist", productData);
  return response.data.message;
};

// get wishlist
const getWishlist = async () => {
  const response = await axios.get(API_URL + "getWishlist");
  return response.data.message;
};

// remove from wishlist
const removeFromWishlist = async (productId) => {
  const response = await axios.put(API_URL + `wishlist${productId}`);
  return response.data.message;
};

// export
const authService = {
  register,
  login,
  logout,
  loginStatus,
  getUser,
  updateUser,
  updatePhoto,
  addToWishlist,
  getWishlist,
  removeFromWishlist,
};

export default authService;
