import React from "react";
import ReactDOM from "react-dom";

import "./Loading.scss";

const Loading = () => {
  return ReactDOM.createPortal(
    <div className="loader-container">
      <div className="loader">
        <div className="loader-inner">
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
          <div className="loader-line-wrap">
            <div className="loader-line"></div>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById("loading")
  );
};

export const Spinner = () => {
  return (
    <div className="--center-all">
      <div className="loader-container">
        <div className="loading">
          <div className="loading-inner">
            <div className="loading-line-wrap">
              <div className="loading-line"></div>
            </div>
            <div className="loading-line-wrap">
              <div className="loading-line"></div>
            </div>
            <div className="loading-line-wrap">
              <div className="loading-line"></div>
            </div>
            <div className="loading-line-wrap">
              <div className="loading-line"></div>
            </div>
            <div className="loading-line-wrap">
              <div className="loading-line"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
