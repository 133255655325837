import React from 'react'
import Conditions from '../../components/condtions/Conditions'
import FooterLinks from '../../components/footer/FooterLinks'

const TermsAndCondition = () => {
  return (
    <div>
      <Conditions />
      <FooterLinks />
    </div>
  )
}

export default TermsAndCondition
