import React from "react";
import "./AboutFooter.scss";
// import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { Link } from "react-router-dom";
import elilocklogo from "../../assets/elilocklogo.png";

const AboutFooter = () => {
  return (
    <>
      {/* <section className="contact-section">
        <div className="container contact">
          <div className="contact-icon">
            <FaFacebook className="i" />
            <FaTwitter className="i" />
            <FaInstagram className="i" />
            <FaYoutube className="i" />
          </div>
          <h2>Contact Us</h2>
          <Link to="/contact" className="btn btn-dark">
            Send Us a Message
          </Link>
        </div>
      </section> */}

      <section className="footer-section">
        <div className="container footer">
          <div className="footer-logo">
            <img src={elilocklogo} alt="logo" />
          </div>
          <div className="footer-menu">
            <p className="link-heading">FEATURES</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/shop">Flash Sales</Link>
              </li>
              <li>
                <Link to="/officialStore">Official Store</Link>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <p className="link-heading">ABOUT US</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/termsAndConditions">Terms and Conditions</Link>
              </li>
              <li>
                <Link to="/about">About Us</Link>
              </li>
              <li>
                <Link to="/officialStore">Offline Stores</Link>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <p className="link-heading">USEFUL LINKS</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/privacyAndPolicy">Privacy & Refund Policy</Link>
              </li>
              <li>
                <Link to="/deliveryOptions">Delivery Options</Link>
              </li>
              <li>
                <Link to="/disputeResolution">Dispute Resolution Policy</Link>
              </li>
            </ul>
          </div>
          <div className="footer-menu">
            <p className="link-heading">NEED HELP?</p>
            <ul className="nav-ul footer-links">
              <li>
                <Link to="/bulkPurchase">Bulk Purchase</Link>
              </li>
              <li>
                <Link to="https://wa.me/2349069983442">Chat with us</Link>
              </li>

              <li>
                <Link to="/contact">Contact Us</Link>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutFooter;
