import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinners from "../../loader/Spinners";

import styles from "./ChangeOrderStatus.module.scss";
import Card from "../../card/Card";
import { useParams } from "react-router-dom";
import { updateOrderStatus } from "../../../redux/features/order/orderSlice";

// import styles from "./ChangeOrderStatus.module.scss";
const ChangeOrderStatus = () => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const [status, setStatus] = useState("");
  const { isLoading } = useSelector((state) => state.order);

  const updateOrder = async (e, id) => {
    e.preventDefault();

    const formData = {
      orderStatus: status,
    };

    await dispatch(updateOrderStatus({ id, formData }));
  };

  return (
    <>
      {isLoading && <Spinners />}

      <div className={styles.status}>
        <Card cardClass={styles.card}>
          <h4>Update Status</h4>
          <form onSubmit={(e) => updateOrder(e, id)}>
            <span>
              <select
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <option value="" disabled>
                  Select Status
                </option>
                <option value="Order Placed...">Order Placed</option>
                <option value="processing">Processing</option>
                <option value="shipped">Shipped</option>
                <option value="delivered">Delivered</option>
                <option value="cancelled">Cancelled</option>
              </select>
            </span>
            <span>
              <button type="submit" className="--btn --btn-primary">
                Update Status
              </button>
            </span>
          </form>
        </Card>
      </div>
    </>
  );
};

export default ChangeOrderStatus;
