import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageMenu from "../../components/pageMenu/PageMenu";

import styles from "./Wishlist.module.scss";
import ProductItem from "../../components/product/productItem/ProductItem";
import {
  getWishlist,
  removeFromWishlist,
} from "../../redux/features/auth/authSlice";
import Spinners from "../../components/loader/Spinners";
const Wishlist = () => {
  const [grid, setGrid] = useState(true);

  const dispatch = useDispatch();
  const { wishlist, isLoading } = useSelector((state) => state.auth);
  console.log("Wishlist", wishlist);

  const removeWishlist = async (product) => {
    console.log(product._id);
    const productId = product._id;
    await dispatch(removeFromWishlist(productId));
    await dispatch(getWishlist());
  };

  useEffect(() => {
    dispatch(getWishlist());
  }, [dispatch]);

  return (
    <>
      <section>
        {/* {isLoading && <Loading />} */}
        <div className="container vh">
          <PageMenu />
          <h2>My Wishlist</h2>
          <div className="--underline"></div>
          <div className={grid ? `${styles.grid}` : `${styles.list}`}>
            {wishlist?.length === 0 ? (
              <p>No product in your wishlist</p>
            ) : (
              <>
                {isLoading && <Spinners />}
                {wishlist?.map((product) => {
                  return (
                    <div key={product._id}>
                      <ProductItem {...product} grid={grid} product={product} />
                      <button
                        className="--btn -btn-primary --btn-block"
                        onClick={() => removeWishlist()}
                      >
                        Remove From Wishlist
                      </button>
                    </div>
                  );
                })}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default Wishlist;
