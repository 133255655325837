import React from "react";

import "./More.css";

import morepic from "../../assets/morepic.png";

const More = () => {
  return (
    <section className=" container paddings more-container" id="more">
      <div className="more-header">
        <div>
          <h2 className="section-more-header">Benefits From Us</h2>
          <p className="section-more-description">
            We have the passion to make everything security accessible to
            everyone wherever in Nigeria and beyond, from your comfort our goal
            is for everyone to be able to have access to all of our services
          </p>
        </div>
        <button className="more-btn bslides">
          {/* {/* navigate to shop */}
          {/* navigate("/shop"); */}
          <a href="/shop">Shop Now</a>
        </button>
      </div>
      <div className="more-content">
        <div className="more-image">
          <img src={morepic} alt="more" />
        </div>
        <div className="more-grid">
          <div className="more-card">
            <h3>1.</h3>
            <h4>Discount</h4>
            <p>You get 20% discount on your first purchase with us</p>
          </div>
          <div className="more-card">
            <h3>2.</h3>
            <h4>Customer Relations</h4>
            <p>
              Our customers can attest to this, we have the best customer
              services, whatever complaint you might have we are 24/7 available
              to response to you
            </p>
          </div>
          <div className="more-card">
            <h3>3.</h3>
            <h4>Shipping</h4>
            <p>
              We offer nationwide delivery when you buy from us, and express
              delivery only applies to our customers in Lagos Nigeria Alone
            </p>
          </div>
          <div className="more-card">
            <h3>4.</h3>
            <h4>Trustworthy</h4>
            <p>
              You get peace of mind when you deal with us, we only sell,
              authentic, premium products, where you get value for your money
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default More;
