export const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};
export const phoneData = [
  {
    id: 1,
    imageurl: "https://i.ibb.co/9wpF4ZX/x.png",
    name: "Iphone 16 Pro Max",
    price: "2000000",
    description: "Some text about the product..",
  },
  {
    id: 2,
    imageurl: "https://i.ibb.co/J7My18f/samm.jpg",
    name: "Samsung 24 ultra",
    price: "1900000",
    description: "Some text about the product..",
  },
  {
    id: 3,
    imageurl: "https://i.ibb.co/1fbTWRF/spark.png",
    name: "Spark 10pro",
    price: "150000",
    description: "Some text about the product..",
  },
  {
    id: 4,
    imageurl: "https://i.ibb.co/vYXq2Ft/infinix.png",
    name: "Infinix Hot 30i",
    price: "130000",
    description: "Some text about the product..",
  },
];

export const productData = [
  {
    id: 1,
    imageurl:
      "https://images.unsplash.com/photo-1560769629-975ec94e6a86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTJ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "Colorful sneakers",
    price: "19.99",
    description: "Some text about the product..",
  },
  {
    id: 2,
    imageurl:
      "https://images.unsplash.com/photo-1542291026-7eec264c27ff?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8cHJvZHVjdHN8ZW58MHx8MHx8&auto=format&fit=crop&w=500&q=60",
    name: "Sport sneakers",
    price: "21.99",
    description: "Some text about the product..",
  },
  {
    id: 3,
    imageurl:
      "https://images.unsplash.com/photo-1546868871-7041f2a55e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjZ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "iWatch",
    price: "99.99",
    description: "Some text about the product..",
  },
  {
    id: 4,
    imageurl:
      "https://images.unsplash.com/photo-1610824352934-c10d87b700cc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "Water Bottle",
    price: "14.99",
    description: "Some text about the product..",
  },
  {
    id: 5,
    imageurl:
      "https://images.unsplash.com/photo-1525966222134-fcfa99b8ae77?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzB8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "Vans sneakers",
    price: "2500.00",
    description: "Some text about the product..",
  },
  {
    id: 6,
    imageurl:
      "https://images.unsplash.com/photo-1585386959984-a4155224a1ad?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MzV8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "Coco Noir",
    price: "5000.00",
    description: "Some text about the product..",
  },
  {
    id: 7,
    imageurl:
      "https://images.unsplash.com/photo-1589782182703-2aaa69037b5b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8OTJ8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "Sunglasses",
    price: "3348.99",
    description: "Some text about the product..",
  },
  {
    id: 8,
    imageurl:
      "https://images.unsplash.com/photo-1625772452859-1c03d5bf1137?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTR8fHByb2R1Y3RzfGVufDB8fDB8fA%3D%3D&auto=format&fit=crop&w=500&q=60",
    name: "Dove cream",
    price: "4349.99",
    description: "Some text about the product..",
  },
];
