import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { CALCULATE_SUBTOTAL } from "../../../redux/features/cart/cartSlices";
import { CartDiscount } from "../../verifyCoupon/VerifyCoupon";
import Card from "../../card/Card";

import styles from "./CheckoutSummary.module.scss";

const CheckoutSummary = () => {
  const dispatch = useDispatch();
  const { coupon } = useSelector((state) => state.coupon);
  const { cartItems, cartTotalQuantity, cartTotalAmount } = useSelector(
    (state) => state.cart
  );

  useEffect(() => {
    dispatch(CALCULATE_SUBTOTAL({ coupon }));
  }, [dispatch, cartItems, coupon]);

  return (
    <div>
      <h3>Checkout Summary</h3>
      <div>
        {cartItems.length === 0 ? (
          <>
            <p>Your cart is empty.</p>
            <button className="--btn">
              <Link to="/shop">Add items to cart</Link>
            </button>
          </>
        ) : (
          <div>
            <p>
              <b>{`Cart Item(s): ${cartTotalQuantity} `}</b>
            </p>
            <div className={styles.text}>
              <h4>SubTotal:</h4>
              <h3>₦{cartTotalAmount.toFixed(2)} </h3>
            </div>

            <CartDiscount />
            {cartItems.map((item) => {
              const { _id, productName, price, cartQuantity } = item;
              return (
                <Card key={_id} cardClass={styles.card}>
                  <h4>Product: {productName} </h4>
                  <p>Quantity: {cartQuantity} </p>
                  <p>Unit Price:{price.toFixed(2)} </p>
                  <p>Set Price: {price * cartQuantity} </p>
                </Card>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default CheckoutSummary;
