import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageMenu from "../../components/pageMenu/PageMenu";
import { IoCloudUploadSharp } from "react-icons/io5";
import { toast } from "react-toastify";

import Card from "../../components/card/Card";
import {
  getUser,
  updatePhoto,
  updateUser,
} from "../../redux/features/auth/authSlice";
// import { toast } from "react-toastify";
import Loading from "../../components/loader/Loading";

import "./Profile.scss";
import { shortenText } from "../../utils";

const cloud_name = process.env.REACT_APP_CLOUD_NAME;
const upload_preset = process.env.REACT_APP_UPLOAD_PRESET;
const url = `https://api.cloudinary.com/v1_1/${cloud_name}/upload`;

const Profile = () => {
  const { isLoading, user } = useSelector((state) => state.auth);

  const initialState = {
    name: user?.name || "",
    email: user?.email || "",
    phone: user?.phone || "",
    role: user?.role || "",
    photo: user?.photo || "",
    address: {
      address: user?.address?.address || "",
      state: user?.address?.state || "",
      country: user?.address?.country || "",
    },
  };

  const [profile, setProfile] = useState(initialState);
  const [profileImage, setProfileImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === null) {
      dispatch(getUser());
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (user) {
      setProfile({
        name: user?.name || "",
        email: user?.email || "",
        phone: user?.phone || "",
        role: user?.role || "",
        photo: user?.photo || "",
        address: {
          address: user?.address?.address || "",
          state: user?.address?.state || "",
          country: user?.address?.country || "",
        },
      });
    }
  }, [dispatch, user]);

  // update profile
  const saveProfile = async (e) => {
    e.preventDefault();
    const userData = {
      name: profile.name,
      email: profile.email,
      phone: profile.phone,
      role: profile.role,
      address: {
        address: profile.address,
        // address2: profile.address.address2,
        // city: profile.address.city,
        state: profile.state,
        country: profile.country,
        // zip: profile.address.zip,
      },
    };

    await dispatch(updateUser(userData));
  };
  // function to change profile image
  const handleImageChange = (e) => {
    setProfileImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile({ ...profile, [name]: value });
  };
  // function to save the changed profile photo
  const savePhoto = async (e) => {
    e.preventDefault();

    let imageURL;
    try {
      if (
        profileImage !== null &&
        profileImage.size <= 2097152 && // 2MB
        profileImage.type.match(/image\/(jpg|jpeg|png|gif|bmp|svg)/)
      ) {
        const image = new FormData();
        image.append("file", profileImage);
        image.append("cloud_name", cloud_name);
        image.append("upload_preset", upload_preset);

        // save image to cloudinary
        const response = await fetch(url, {
          method: "POST",
          body: image,
        });
        const imgData = await response.json();
        console.log(imgData);
        imageURL = imgData.url.toString();
      }

      // save to mongoDB
      const userData = {
        photo: profileImage ? imageURL : profile.photo,
      };
      await dispatch(updatePhoto(userData));
      setProfileImage(null);
    } catch (error) {
      console.error(error);
      toast.error("Failed to save photo");
    }
  };

  return (
    <>
      <section>
        {isLoading && <Loading />}
        <div className="container">
          <PageMenu />
          <h2>Welcome: {user?.name}</h2>
          <div className="--flex-start profile">
            <Card cardClass={"card"}>
              {!isLoading && (
                <>
                  <div className="profile-photo">
                    <div>
                      <img
                        src={imagePreview === null ? user?.photo : imagePreview}
                        alt="profile-image"
                      />
                      <h3>Role: &nbsp; {profile.role}</h3>
                      {imagePreview !== null && (
                        <div className="--center-all">
                          <button
                            className="--btn --btn-secondary"
                            onClick={savePhoto}
                          >
                            <IoCloudUploadSharp size={18} /> &nbsp; Change Photo
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <form onSubmit={saveProfile}>
                    <p>
                      <label>Change Photo</label>
                    </p>
                    <input
                      type="file"
                      accept="image/*"
                      name="image"
                      onChange={handleImageChange}
                    />
                    <p>
                      <label>Name:</label>
                      <input
                        type="text"
                        name="name"
                        value={profile?.name}
                        onChange={handleInputChange}
                        autoComplete="name"
                        required
                      />
                    </p>

                    <p>
                      <label>Email:</label>
                      <input
                        type="email"
                        name="email"
                        value={profile?.email}
                        onChange={handleInputChange}
                        autoComplete="email"
                        disabled
                      />
                    </p>

                    <p>
                      <label>Phone Number:</label>
                      <input
                        type="text"
                        name="phone"
                        value={profile?.phone}
                        onChange={handleInputChange}
                        autoComplete="phone"
                        required
                      />
                    </p>

                    <p>
                      <label>Address:</label>
                      <input
                        type="text"
                        name="address"
                        value={profile?.address?.address}
                        onChange={handleInputChange}
                        autoComplete="address"
                        required
                      />
                    </p>
                    <p>
                      <label>State:</label>
                      <input
                        type="text"
                        name="state"
                        value={profile?.address?.state}
                        onChange={handleInputChange}
                        autoComplete="address"
                        required
                      />
                    </p>
                    <p>
                      <label>Country:</label>
                      <input
                        type="text"
                        name="country"
                        value={profile?.address?.country}
                        onChange={handleInputChange}
                        autoComplete="address"
                        required
                      />
                    </p>
                    <button className="--btn --btn-primary --btn-block">
                      Update Profile
                    </button>
                  </form>
                </>
              )}
            </Card>
          </div>
        </div>
      </section>
    </>
  );
};

export const Username = () => {
  const { user } = useSelector((state) => state.auth);

  const username = user?.name || "...";

  return (
    <span className="profile_username">Hi {shortenText(username, 9)}</span>
  );
};

export default Profile;
