import React from "react";
import PrivacyPolicy from "../../components/condtions/PrivacyPolicy";
import FooterLinks from "../../components/footer/FooterLinks";

const Privacy = () => {
  return (
    <div>
      <PrivacyPolicy />
      <FooterLinks />
    </div>
  );
};

export default Privacy;
