export const reviews = [
  {
    id: 1,
    image:
      "https://i.ibb.co/x6T6D8m/pexels-nashon-otieno-461418010-15638694.jpg",
    name: "Adebowale Daniel",
    text: "I was extremely pleased with the quality of the product. It exceeded my expectations and provided great value for the price.",
  },
  {
    id: 2,
    image:
      "https://i.ibb.co/BgvPtG2/joshua-oluwagbemiga-8q-Farl-WZY-U-unsplash.jpg",
    name: "Chuks Urgo",
    text: "The customer service was excellent. They were responsive and helpful throughout the entire process, making it a smooth experience for me.",
  },
  {
    id: 3,
    image: "https://i.ibb.co/f2CPtQd/maatla-seetelo-8-XN-l2zyr-WE-unsplash.jpg",
    name: "Emeka Amiose",
    text: "The attention to detail in their work is impressive. Every aspect of the project was handled with precision and care. I highly recommend their services.",
  },
  {
    id: 4,
    image:
      "https://i.ibb.co/mrpJcRM/joshua-oluwagbemiga-1-K7y-DWuam-RA-unsplash.jpg",
    name: "Damilare Fasasi",
    text: "The team demonstrated a deep understanding of my requirements. They were able to capture the essence of my vision and deliver a product that exceeded my expectations.",
  },
  {
    id: 5,
    image: "https://i.ibb.co/MNLNCVm/etty-fidele-J1j-YLLl-Rp-A4-unsplash.jpg",
    name: "Funmi Balogun",
    text: "The product not only met but exceeded my expectations. It's clear that the team is dedicated to delivering high-quality work. I'm a satisfied customer.",
  },
];
