import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

import Card from "../../components/card/Card";
import CheckoutSummary from "../../components/checkout/checkoutSummary/CheckoutSummary";
import {
  selectCartItems,
  selectCartTotalAmount,
} from "../../redux/features/cart/cartSlices";
import { selectUser } from "../../redux/features/auth/authSlice";

import styles from "./Flutterwave.module.scss";
import {
  selectPaymentMethod,
  selectShippingAddress,
} from "../../redux/features/checkout/checkoutSlice";
import { toast } from "react-toastify";
import { createOrder } from "../../redux/features/order/orderSlice";

const PayWithFlutterwave = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector(selectUser);
  const cartTOtalAmount = useSelector(selectCartTotalAmount);
  const cartItems = useSelector(selectCartItems);
  const shippingAddress = useSelector(selectShippingAddress);
  const paymentMethod = useSelector(selectPaymentMethod);

  const { coupon } = useSelector((state) => state.coupon);

  const [urlParams] = useSearchParams();
  const payment = urlParams.get("payment");
  const ref = urlParams.get("ref");

  const saveOrder = () => {
    const today = new Date();
    const formData = {
      orderDate: today.toDateString(),
      orderTime: today.toLocaleTimeString(),
      orderAmount: cartTOtalAmount,
      orderStatus: "Order Placed...",
      cartItems,
      shippingAddress,
      paymentMethod,
      coupon: coupon != null ? coupon : { name: "nil" },
    };
    dispatch(createOrder(formData));
  };

  useEffect(() => {
    if (
      payment === "successful" &&
      ref === process.env.REACT_APP_TX_REF &&
      cartTOtalAmount > 0
    ) {
      toast.success("Payment Successful");
      saveOrder();
    }

    if (payment === "failed") {
      toast.error("Payment Failed");
      navigate("/cart");
    }
    // Redirect to checkout success page after 4 seconds if payment is successful
    setTimeout(() => {
      if (payment === "successful" && ref === process.env.REACT_APP_TX_REF) {
        navigate("/checkout-success");
      }
    }, 2000);
  }, [payment, ref, cartTOtalAmount, navigate]);

  function makeNewPayment() {
    var randomNumber = Math.floor(Math.random() * 1000);

    // eslint-disable-next-line no-undef
    FlutterwaveCheckout({
      public_key: process.env.REACT_APP_FLW_PK,
      tx_ref: "txref-DI0NzMx19" + randomNumber,
      amount: cartTOtalAmount,
      currency: "NGN",
      payment_options: ["card", "bank", "ussd", "mobilemoneyrwanda"], //"card, banktransfer, ussd, mobilemoneyrwanda",
      callback: function (response) {
        console.log(response);

        const reference = response.tx_ref;
        const transactionId = response.transaction_id;
        if (
          response.charge_response_code === "00" ||
          response.status === "successful"
        ) {
          // redirect to a verification page
          // window.location = `${process.env.REACT_APP_FRONTEND_URL}/checkout-success`;
          window.location = "https://www.elilock.com/checkout-success";
          saveOrder();

          toast.success("Congrats Payment Successful!");
        } else {
          // redirect to a verification page
          // window.location = `${process.env.REACT_APP_FRONTEND_URL}/cart`;
          window.location = "https://www.elilock.com/cart";
          toast.error("Sorry Payment Failed");
        }
      },

      customer: {
        email: user.email,
        phone_number: user.phone,
        name: user.name,
      },
      customizations: {
        title: "Elilock Integrated Services",
        description: "Payment for Product",
        logo: "https://i.ibb.co/T1ryJ5h/3232.png",
      },
    });
  }

  return (
    <>
      <section style={{ height: "81vh" }}>
        <div className={`container ${styles.checkout}`}>
          <h2>Checkout</h2>
          <form>
            <div>
              <Card cardClass={styles.card}>
                <CheckoutSummary />
              </Card>
            </div>
            <div>
              <Card cardClass={`${styles.card} ${styles.pay}`}>
                <h3>Pay with FlutterWave</h3>
                <button
                  type="button"
                  className={styles.button}
                  onClick={() => makeNewPayment()}
                  disabled={cartItems.length === 0}
                >
                  Pay Now
                </button>
              </Card>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default PayWithFlutterwave;
