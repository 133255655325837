import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { BsFillTrash3Fill } from "react-icons/bs";

import {
  deleteBrand,
  getBrands,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";

const BrandList = () => {
  const { brands } = useSelector((state) => state.category);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBrands());
  }, [dispatch]);

  const confirmDelete = (slug) => {
    confirmAlert({
      title: "Delete Brand",
      message: "Are you sure you want to delete this brand?",
      buttons: [
        {
          label: "Yes",
          onClick: () => removeBrand(slug),
        },
        {
          label: "No",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };
  
  const removeBrand = async (slug) => {
    await dispatch(deleteBrand(slug));
    await dispatch(getBrands()); // Reload brands after deletion
  };

  return (
    <div className="--mb2">
      <h3>All Brands</h3>
      <div className="table">
        {brands.length === 0 ? (
          <p> No Brand Found!</p>
        ) : (
          <table>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Name</th>
                <th>Category</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {brands.map((brand, index) => {
                const { _id, name, slug, category } = brand;
                return (
                  <tr key={_id}>
                    <td>{index + 1}</td>
                    <td>{name}</td>
                    <td>{category}</td>
                    <td>
                      <span>
                        <BsFillTrash3Fill
                          size={20}
                          color={"red"}
                          onClick={() => confirmDelete(slug)}
                        />
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default BrandList;
