import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getProducts } from "../../redux/features/product/productSlice";
import HomeInfoBox from "./HomeInfoBox";

import CarouselItem from "../../components/carousel/CarouselItem";
import ProductCarousel from "../../components/carousel/Carousel";
import ProductCategory from "./ProductCategory";
import FooterLinks from "../../components/footer/FooterLinks";

import "./Home.scss";
import { Link} from "react-router-dom";
import NewSlide from "../../components/slider/NewSlide";

const PageHeading = ({ heading, btnText }) => {
  return (
    <>
      <div className="--flex-between">
        <h2 className="--fw-thin">{heading}</h2>
        <Link to="/shop" className="--btn --btn-outline">
          <button className="--btn --btn-active">{btnText}</button>
        </Link>
      </div>
      <div className="--hr"></div>
    </>
  );
};

const Home = () => {
  const dispatch = useDispatch();
  const { products } = useSelector((state) => state.product);

  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);

  const latest = products
    ?.filter((product) => {
      return product?.quantity > 0;
    })
    ?.filter(
      (product, index) => index < 7

      // return product.category === "goods"
    );

  const smartLocks = products
    ?.filter((product) => {
      return product?.quantity > 0;
    })
    ?.filter((product) => {
      return product?.category === "Smart Locks";
    })
    ?.filter((product, index) => index < 7);

  const brandsOne = products
    ?.filter((product) => {
      return product?.quantity > 0;
    })
    ?.filter((product) => {
      return product?.brand === "Elilock";
    })
    ?.filter((product, index) => index < 7);

  const newBrandsOne = brandsOne.map((item) => (
    <div key={item.id}>
      <CarouselItem
        productName={item.productName}
        url={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        description={item.description}
        product={item}
      />
    </div>
  ));

  const latestProduct = latest.map((item) => (
    <div key={item.id}>
      <CarouselItem
        productName={item.productName}
        url={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        description={item.description}
        product={item}
      />
    </div>
  ));

  const locks = smartLocks.map((item) => (
    <div key={item.id}>
      <CarouselItem
        productName={item.productName}
        url={item.image[0]}
        price={item.price}
        regularPrice={item.regularPrice}
        description={item.description}
        product={item}
      />
    </div>
  ));

  return (
    <>     
      {/* <Slider /> */}
      <NewSlide />
      <section>
        <div className="container">
          <HomeInfoBox />
          <PageHeading heading={"Latest Product"} btnText={"SHOP NOW"} />
          <ProductCarousel products={latestProduct} />
        </div>
      </section>
      <section className="--bg-grey">
        <div className="container">
          <h3 className="--title-center">Product Categories</h3>
          <ProductCategory />
        </div>
      </section>

      <section>
        <div className="container">
          <PageHeading heading={"Smart Locks"} btnText={"SHOP NOW"} />
          <ProductCarousel products={locks} />
        </div>
      </section>

      <section>
        <div className="container">
          <PageHeading heading={"ELILOCK BRAND"} btnText={"SHOP NOW"} />
          <ProductCarousel products={newBrandsOne} />
        </div>
      </section>
      <FooterLinks />
    </>
  );
};

export default Home;
