import React from 'react'
import "./PayCard.css"
import Flutterwave from "../../assets/Flutterwavecard.png";
import mastercard from "../../assets/mastercard.png";
import visacard from "../../assets/visacard.png";
import paystack from "../../assets/paystack.png";

const PayCard = () => {
  return (
    <section className="c-wrapper">
        <div className="cardtext">
            <h3>You Can be rest Assured with our easy to use payment platform</h3>
        </div>
        <div className="paddings innerWidth flexCenter c-container">
            <img src={Flutterwave} alt='logo' />
            <img src={mastercard} alt='logo' />
            <img src={visacard} alt='logo' />
            <img src={paystack} alt='logo' />
        </div>
    </section>
  )
}

export default PayCard
