import React from "react";

import "./PictureGrid.scss";

const image1 = "https://i.ibb.co/k41D5sk/service1.png";
const image2 = "https://i.ibb.co/C03p6BY/service2.png";
const image3 = "https://i.ibb.co/VWgMPb1/service3.png";
const image4 = "https://i.ibb.co/KKRjqkY/service4.png";

const images = [image1, image2, image3, image4];
const PictureGridOne = () => {
  return (
    <div className="containers">
      <div className="grids">
        {images.map((image, index) => (
          <div className="grids-item" key={index}>
            <img src={image} alt={`sales ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
  );
};
export default PictureGridOne;
