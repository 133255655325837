import React from "react";
import { FaUser } from "react-icons/fa6";

import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/features/auth/authSlice";

import styles from "./Navbar.module.scss";
import { NavLink } from "react-router-dom";

const activeLink = ({ isActive }) => (isActive ? `${styles.active}` : "");

const Navbar = () => {
  const user = useSelector(selectUser);
  const userName = user?.name;

  return (
    <div className={styles.navbar}>
      <div className={styles.user}>
        <FaUser size={40} color="#fff" />
        <h4>{userName}</h4>
      </div>
      <nav>
        <ul>
          <li>
            <NavLink to={"/admin/home"} className={activeLink}>
              HOME
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/all-products"} className={activeLink}>
              ALL PRODUCTS
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/add-product"} className={activeLink}>
              ADD PRODUCT
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/orders"} className={activeLink}>
              ORDERS
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/coupon"} className={activeLink}>
              COUPON
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/category"} className={activeLink}>
              CATEGORY
            </NavLink>
          </li>
          <li>
            <NavLink to={"/admin/brand"} className={activeLink}>
              BRAND
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Navbar;
