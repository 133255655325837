import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Loading from "../../loader/Loading";
import ProductForm from "../productForm/ProductForm";
import {
  getBrands,
  getCategories,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";
import {
  createProduct,
  RESET_PROD,
} from "../../../redux/features/product/productSlice";

import "./AddProduct.scss";
import { toast } from "react-toastify";

const initialState = {
  productName: "",
  category: "",
  brand: "",
  quantity: "",
  color: "",
  price: "",
  regularPrice: "",
};

const AddProduct = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [description, setDescription] = useState("");

  const [files, setFiles] = useState([]);

  const { isLoading, message } = useSelector((state) => state.product);

  const [product, setProduct] = useState(initialState);
  const { productName, category, brand, price, quantity, color, regularPrice } =
    product;

  const generateSku = (category) => {
    const letter = category.slice(0, 3).toUpperCase();
    const number = Date.now();
    const sku = letter + "-" + number;

    return sku;
    // const randomNumber = Math.floor(Math.random() * 1000000000);
    // return `PRD-${randomNumber}`;
  };

  // function to create product and add to the database

  const saveProduct = async (e) => {
    e.preventDefault();

    if (files.length < 1) {
      return toast.error("Please Add Product Images");
    }

    const formData = {
      productName,
      sku: generateSku(category),
      category,
      brand,
      price,
      quantity,
      color,
      regularPrice,
      description,
      image: files,
    };
    await dispatch(createProduct(formData));
  };

  useEffect(() => {
    if (message === "Product Created Successfully") {
      navigate("/admin/all-products");
    }
    dispatch(RESET_PROD());
  }, [message, navigate, dispatch]);

  return (
    <section>
      <div className="container">
        {isLoading && <Loading />}
        <h3 className="--mt">Add New Product</h3>

        <ProductForm
          saveProduct={saveProduct}
          product={product}
          setProduct={setProduct}
          isEditing={false}
          description={description}
          setDescription={setDescription}
          files={files}
          setFiles={setFiles}
        />
      </div>
    </section>
  );
};
export default AddProduct;
