import React, { useEffect, useState } from "react";
import { BsFillTrash3Fill } from "react-icons/bs";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import Card from "../../card/Card";
import UploadWidget from "./UploadWidget";

import "./ProductForm.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  getBrands,
  getCategories,
} from "../../../redux/features/categoryAndBrand/CategoryAndBrandSlice";

const ProductForm = ({
  saveProduct,
  isEditing,
  product,
  setProduct,
  description,
  setDescription,
  files,
  setFiles,
}) => {
  const dispatch = useDispatch();

  const [filteredBrands, setFilteredBrands] = useState([]);

  const { categories, brands } = useSelector((state) => state.category);
  useEffect(() => {
    dispatch(getCategories());
    dispatch(getBrands());
  }, [dispatch]);

  //  filtered brands based on selected Category
  const filterBrands = (selectedCategory) => {
    const newBrands = brands.filter(
      (brand) => brand.category === selectedCategory
    );
    setFilteredBrands(newBrands);
  };

  useEffect(() => {
    filterBrands(product?.category);
  }, [product?.category]);

  const handleInputChange = (e) => {
    // const { name, value } = e.target
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const removeImage = (image) => {
    // Remove the image from the files array and update the state
    setFiles(files.filter((img) => img !== image));
    // const updatedFiles = files.filter((img) => img!== image);
    // setFiles(updatedFiles);
  };

  return (
    <div className="add-product">
      <UploadWidget files={files} setFiles={setFiles} />
      <Card cardClass={"card"}>
        <br />
        <form onSubmit={saveProduct}>
          <label>Product Images:</label>
          <div className="slide-container">
            <aside>
              {files.length > 0 &&
                files.map((image) => (
                  <div key={image} className="thumbnail">
                    <img src={image} alt="productImage" height={100} />
                    <div>
                      <BsFillTrash3Fill
                        className="thumbnailIcon"
                        size={25}
                        color="red"
                        onClick={() => removeImage(image)}
                      />
                    </div>
                  </div>
                ))}
              {files.length < 1 && (
                <p className="--m">No images uploaded yet.</p>
              )}
            </aside>
          </div>

          <label>Product Name:</label>
          <input
            type="text"
            name="productName"
            placeholder="Product Name"
            value={product?.productName}
            onChange={handleInputChange}
            required
          />
          <label>Product Category:</label>
          <select
            name="category"
            value={product?.category}
            onChange={handleInputChange}
            required
          >
            {isEditing ? (
              <option value={product?.category}>{product?.category}</option>
            ) : (
              <option> select category</option>
            )}
            {categories.length > 0 &&
              categories.map((category) => (
                <option key={category._id} value={category.name}>
                  {category.name}
                </option>
              ))}
          </select>

          <label>Product Brand:</label>
          <select
            name="brand"
            value={product?.brand}
            onChange={handleInputChange}
            required
          >
            {isEditing ? (
              <option value={product?.brand}>{product?.brand}</option>
            ) : (
              <option> select Brand</option>
            )}
            {filteredBrands.length > 0 &&
              filteredBrands.map((brand) => (
                <option key={brand._id} value={brand.name}>
                  {brand.name}
                </option>
              ))}
          </select>

          <label>Product Color:</label>
          <input
            type="text"
            placeholder="color"
            name="color"
            value={product?.color}
            onChange={handleInputChange}
            required
          />
          <label>Product Regular Price:</label>
          <input
            type="number"
            placeholder="price"
            name="regularPrice"
            value={product?.regularPrice}
            onChange={handleInputChange}
            required
          />

          <label>Product Sale Price:</label>
          <input
            type="number"
            placeholder="Product price"
            name="price"
            value={product?.price}
            onChange={handleInputChange}
            required
          />
          <label>Product Quantity</label>
          <input
            type="number"
            placeholder="Product Quantity"
            name="quantity"
            value={product?.quantity}
            onChange={handleInputChange}
            required
          />

          <label>Product Description:</label>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={ProductForm.modules}
            formats={ProductForm.formats}
            required
          />

          <div className="--my">
            <button type="submit" className="--btn --btn-primary">
              Save product
            </button>
          </div>
        </form>
      </Card>
    </div>
  );
};

ProductForm.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ align: [] }],
    [{ color: [] }, { background: [] }],

    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["clean"],
  ],
};

ProductForm.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "color",
  "background",
  "align",
  "list",
  "bullet",
  "indent",
];

export default ProductForm;
