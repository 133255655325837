import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { GrView } from "react-icons/gr";
import { RiEditBoxLine } from "react-icons/ri";
import { BsFillTrash3Fill } from "react-icons/bs";
import ReactPaginate from "react-paginate";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import { selectIsLoggedIn } from "../../../redux/features/auth/authSlice";
import {
  deleteProduct,
  getProducts,
} from "../../../redux/features/product/productSlice";
import Search from "../../search/Search";
import { Spinner } from "../../loader/Loading";

import "./ViewProduct.scss";
import { shortenText } from "../../../utils";

const ViewProduct = () => {
  const disPatch = useDispatch();
  const [search, setSearch] = useState("");
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const { products, isLoading } = useSelector((state) => state.product);

  useEffect(() => {
    if (isLoggedIn) {
      disPatch(getProducts()); // Fetch products when the component mounts if the user is logged in.  This is done to ensure that the products are always up-to-date.  If you want to fetch products only when the user logs in, you would need to modify this logic.  For example, you could add a condition to only fetch products when the user is logged in.  For example: if (isLoggedIn) { disPatch(fetchProducts()); }  However, this would require additional state management to keep track of whether or not the user is logged in.  For simplicity, this example assumes that the user is logged in.  If the user is not logged in, the products will not be fetched.  You would need to add additional logic to handle this case.  For example, you could display a loading indicator or a message indicating that the user must be logged in to view products.  In a real-world application, you would want
    }
  }, [isLoggedIn, disPatch]);

  const refresh = () => {
    disPatch(getProducts());
  };

  const confirmDelete = (id) => {
    confirmAlert({
      title: "Delete Product",
      message: "Are you sure you want to delete this product?",
      buttons: [
        {
          label: "Delete",
          onClick: () => removeProduct(id),
        },
        {
          label: "Cancel",
          // onClick: () => alert('Click No')
        },
      ],
    });
  };

  const removeProduct = async (id) => {
    await disPatch(deleteProduct(id));
    await disPatch(getProducts());
  };

  // Pagination
  const itemsPerPage = 10;

  const [itemOffset, setItemOffset] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = products.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(products.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % products.length;
    setItemOffset(newOffset);
  };

  return (
    <section>
      <div className="container product-list">
        <div className="table">
          <div className="--flex-between --flex-dir-column">
            <span>
              <h3>All Products</h3>
              <p>
                - <b>{products.length}</b> Products Found
              </p>
            </span>
            <span>
              <Search
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </span>
          </div>
        </div>
        {isLoading && <Spinner />}
        <div className="table">
          {!isLoading && products.length === 0 ? (
            <p>No product found...</p>
          ) : (
            <table>
              <thead>
                <tr>
                  <td>S/N</td>
                  <td>Name</td>
                  <td>Category</td>
                  <td>Price</td>
                  <td>Quantity</td>
                  <td>Value</td>
                  <td>Action</td>
                </tr>
              </thead>
              <tbody>
                {currentItems.map((product, index) => {
                  const { _id, productName, category, price, quantity } =
                    product;
                  return (
                    <tr key={_id}>
                      <td>{index + 1}</td>
                      <td>{shortenText(productName, 16)}</td>
                      <td>{category}</td>
                      <td>₦{price}</td>
                      <td>{quantity}</td>
                      <td>₦{price * quantity}</td>
                      <td className="icons">
                        <span>
                          <Link to="/">
                            <GrView size={20} color={"purple"} />
                          </Link>
                        </span>
                        <span>
                          <Link to={`/admin/edit-product/${_id}`}>
                            <RiEditBoxLine size={20} color={"green"} />
                          </Link>
                        </span>
                        <span>
                          <BsFillTrash3Fill
                            size={20}
                            color={"red"}
                            onClick={() => confirmDelete(_id)}
                          />
                        </span>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          )}
        </div>
        <ReactPaginate
          breakLabel="..."
          nextLabel="Next"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          pageCount={pageCount}
          previousLabel="Prev"
          renderOnZeroPageCount={null}
          containerClassName="pagination"
          pageLinkClassName="page-num"
          previousLinkClassName="page-num"
          nextLinkClassName="page-num"
          activeClassName="active-page"
          initialPage={0}
        />
      </div>
    </section>
  );
};

export default ViewProduct;
