import {
  MdGppGood,
  MdOutlineSecurityUpdateGood,
  MdLoyalty,
} from "react-icons/md";
import { IoPricetags } from "react-icons/io5";
import { RiCustomerService2Fill } from "react-icons/ri";
import { GiThink } from "react-icons/gi";
const data = [
  {
    icon: <IoPricetags />,
    heading: "Best Price in The Market",
    detail:
      "At EliLock we can guarantee you that we have the best price you can get anywhere, we deliver premium services and still we give the best prices compared to anywhere else.",
  },
  {
    icon: <RiCustomerService2Fill />,
    heading: "Customer-Centric Approach.",
    detail:
      "Our top priority is ensuring customer satisfaction and fostering loyalty. We achieve this by delivering exceptional service that meets their unique needs.",
  },
  {
    icon: <GiThink />,
    heading: "Innovations",
    detail:
      "To maintain a competitive edge, we consistently enhance our offerings and adapt to market changes which makes us stay ahead of our competitors and deliver exceptional services to all our customers.",
  },
  {
    icon: <MdLoyalty />,
    heading: "Our Integrity",
    detail:
      "We foster trust with our customers by consistently demonstrating honesty, transparency, and ethical behavior, which is second to none anywhere else. We take our integrity seriously and we are in service to make sure nothing stains our integrity at EliLock. We are known for keeping to our words and delivering exceptional services and we intend to keep it that way.",
  },
  {
    icon: <MdOutlineSecurityUpdateGood />,
    heading: "Quality",
    detail:
      "We make sure that we consistently monitor and maintain high standards to deliver superior products or services. Any product you get from us is nothing but the best, we make sure that we do our diligence with any product we showcase and make sure that nothing but the best can be achieved with such products.",
  },
  {
    icon: <MdGppGood />,
    heading: "All Round Excellence",
    detail:
      "At EliLock we strive to make sure all our customers have the best possible experience when dealing with us, that is why we made is possible that customers can get help 24/7 with questions or complaints that they may have. Our customer care representatives are ever ready to attend to them.",
  },
];
export default data;
