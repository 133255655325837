import React from "react";
import { BsCash } from "react-icons/bs";
import { SlCalender } from "react-icons/sl";
import { FaMoneyCheckDollar } from "react-icons/fa6";
import "./HotDeals.css";

const HotDeals = () => {
  return (
    <section className="container deals-container">
      <div className="deals-card">
        <h2 className="deals-header">Hot 🔥 deals for you</h2>
        <p className="deals-header-p">
          Best online deals for our new and existing customers
        </p>
      </div>
      <div className="deals-card">
        <div className="deals-card-content">
          <span>
            <BsCash className="cash-line" />
          </span>
          <h4>1.5% Cash Back</h4>
          <p>Earn a 5% cashback reward on your first purchase with us</p>
        </div>
      </div>
      <div className="deals-card">
        <div className="deals-card-content">
          <span>
            <SlCalender className="deals-calender" />
          </span>
          <h4>30 Days Terms</h4>
          <p>
            Take advantage of our flexible payment plans on some selected items
          </p>
        </div>
      </div>
      <div className="deals-card">
        <div className="deals-card-content">
          <span>
            <FaMoneyCheckDollar className="deal-money" />
          </span>
          <h4>Save Money</h4>
          <p>
            Discover unbeatable prices and save big money on premium products
          </p>
        </div>
      </div>
    </section>
  );
};

export default HotDeals;
