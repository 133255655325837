import React from 'react';
import './DeliveryOptions.scss';

const DeliveryOption = () => {
  return (
    <div className="delivery-options-container">
      <h1>Delivery Options for Elilock Integrated Services</h1>
      <p className="intro">
        At Elilock Integrated Services, we offer flexible delivery options to meet your needs. Whether you're in Lagos, outside Lagos, or overseas, we ensure your products are delivered safely and on time.
      </p>

      <div className="section">
        <h2>1. Nationwide Delivery</h2>
        <p>
          We deliver our products to all states within Nigeria. Our delivery timelines are as follows:
          <ul>
            <li><strong>Lagos State:</strong> Express shipping is available for Lagos residents. Delivery typically takes <strong>1-2 business days</strong>.</li>
            <li><strong>Outside Lagos:</strong> Delivery to other states in Nigeria typically takes <strong>7 business days</strong>.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>2. Overseas Delivery</h2>
        <p>
          We also offer international shipping to customers outside Nigeria. Our overseas delivery timelines are as follows:
          <ul>
            <li><strong>Standard Overseas Delivery:</strong> Delivery typically takes <strong>7 business days</strong>.</li>
            <li><strong>Express Overseas Delivery:</strong> Express shipping is available for overseas customers, but the delivery time is not fixed and depends on the destination and shipping provider.</li>
          </ul>
        </p>
      </div>

      <div className="section">
        <h2>3. Shipping Fees</h2>
        <p>
          Shipping fees are not fixed and are subject to change based on the current rates. Factors that influence shipping fees include:
          <ul>
            <li>Delivery location (within Lagos, outside Lagos, or overseas).</li>
            <li>Product weight and dimensions.</li>
            <li>Shipping method (standard or express).</li>
          </ul>
          <strong>Note:</strong> A shipping quote will be provided at the time of order confirmation.
        </p>
      </div>

      <div className="section">
        <h2>4. Tracking Your Order</h2>
        <p>
          Once your order has been shipped, you will receive a tracking number to monitor the status of your delivery. You can track your order through our website or the shipping provider's platform.
        </p>
      </div>

      <div className="section">
        <h2>5. Contact Us</h2>
        <p>
          For questions or concerns about delivery options, please contact us at:
          <br />
          <strong>Elilock Integrated Services</strong>
          <br />
          No. 2 Jinhua Street, Ikota Lekki, Ajah, Lagos State, Nigeria
          <br />
          Phone: +2349069983442
          <br />
          Email: elilockintegratedservices@gmail.com
        </p>
      </div>

      <p className="thank-you">Thank you for choosing Elilock Integrated Services!</p>
    </div>
  );
};

export default DeliveryOption;