import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
  AccordionItemState,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";
import { IoIosArrowDropdownCircle } from "react-icons/io";
import "./Values.css";
import data from "../../utils/accordion";

const Values = () => {
  return (
    <section className="container">
      <div className="values-container">
        {/* right - side */}

        <div className="flexColCenter">
          <span className="orangeText core-values">OUR CORE VALUES</span>
          <span className="primaryText core1-values">
            Below are few of the values you get from us
          </span>
          <span className="secondaryText text-values">
            At EliLock Integrated services Premium services is all you get from
            us, <br />
            this is a place where optimal satisfaction is guaranteed
          </span>
          <Accordion
            className="accordion"
            allowMultipleExpanded={false}
            preExpanded={[0]}
          >
            {data.map((item, i) => {
              // eslint-disable-next-line react-hooks/rules-of-hooks
              const [className, setClassName] = useState(null);
              return (
                <AccordionItem
                  className={`accordionItem ${className}`}
                  key={i}
                  uuid={i}
                >
                  <AccordionItemHeading>
                    <AccordionItemButton className=" flexCenter accordionButton">
                      <AccordionItemState>
                        {({ expanded }) =>
                          expanded
                            ? setClassName("expanded")
                            : setClassName("collapsed")
                        }
                      </AccordionItemState>
                      <div className="flexCenter icon">{item.icon}</div>
                      <span className=" value-heading">{item.heading}</span>
                      <div className="flexCenter icon">
                        <IoIosArrowDropdownCircle size={20} />
                      </div>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p className="secondaryText value-detail">{item.detail}</p>
                  </AccordionItemPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </div>
    </section>
  );
};

export default Values;
