import React from "react";
import CouponList from "./CouponList";
import CreateCoupon from "./CreateCoupon";


import "./Coupon.scss"

const Coupon = () => {
  return(
    <section>
    <div className="container coupon">
      <CreateCoupon />
      <CouponList />
    </div>
  </section>
  )
};

export default Coupon;
