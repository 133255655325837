import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";
import { useDispatch } from "react-redux";

import Card from "../../card/Card";
import { calculateAverageRating, shortenText } from "../../../utils";
import ProductRating from "../productRating/ProductRating";
import {
  ADD_TO_CART,
  saveCartDB,
} from "../../../redux/features/cart/cartSlices";

import styles from "./ProductItem.module.scss";

const ProductItem = ({
  productName,
  grid,
  _id,
  product,
  price,
  image,
  regularPrice,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const averageRating = calculateAverageRating(product?.ratings);

  const addToCart = (product) => {
    dispatch(ADD_TO_CART(product));
    dispatch(
      saveCartDB({ cartItems: JSON.parse(localStorage.getItem("cartItems")) })
    );
  };

  const buyNow = () => {
    // toast.success("Product added to cart successfully!");
    dispatch(ADD_TO_CART(product));
    navigate("/cart");
  };

  return (
    <Card cardClass={grid ? `${styles.grid}` : `${styles.list}`}>
      <Link to={`/product-details/${_id}`}>
        <div className={styles.img}>
          <img src={image[0]} alt={productName} />
        </div>
      </Link>
      <div className={styles.content}>
        <div className={styles.details}>
          <p>
            <span>{regularPrice > 0 && <del>₦{regularPrice}</del>}</span>
            {`  ₦${price}`}
          </p>
          <ProductRating
            averageRating={averageRating}
            noOfRatings={product?.ratings?.length}
          />
          <h4>{shortenText(productName, 18)}</h4>
          {!grid && (
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(
                  shortenText(product?.description, 200)
                ),
              }}
            ></div>
          )}
          {product?.quantity > 0 ? (
            <button
              className="--btn --btn-primary"
              onClick={() => addToCart(product)}
            >
              Add To Cart
            </button>
          ) : (
            <button
              className="--btn --btn-red"
              onClick={() => toast.error("Sorry, Product is out of stock")}
            >
              Out of Stock
            </button>
          )}

          {/* {product?.quantity > 0 ? (
            <button className="--btn --btn-success" onClick={() => buyNow()}>
              Buy Now
            </button>
          ) : (
            <button
              className="--btn --btn-red"
              onClick={() => toast.error("Sorry, Product is out of stock")}
            >
              Out of Stock
            </button>
          )} */}
        </div>
      </div>
    </Card>
  );
};

export default ProductItem;
