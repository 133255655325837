import React from "react";
import "./History.css";


import elilocklogo from "../../assets/elilocklogo.png";

const History = () => {
  return (
    <section className="container history-hero">
      <div className="history-heading">
        <h1>Our History</h1>
      </div>
      <div className="history-container">
        <div className="history-hero-content">
          <h2>Know More About Our we got Started</h2>
          <p>
            EliLock Integrated Services, established in 2023, is a trusted
            provider of cutting-edge security solutions. Our commitment lies in
            delivering unparalleled satisfaction to our valued customers. Our
            core values are what set us apart. EliLock was formed with customers
            in mind, fast accessibility to everything that involves security.
            <br />
            <br />
            We make sure that what ever your needs might be you are just one
            click away, we have express shipping in anywhere in Lagos state
            Nigeria. Our services cross across all over Nigeria and even
            oversea. <br /> <br />
            We are trustworthy and our clients can testify to this. Premium
            services or nothing else when it comes to EliLock
          </p>
          {/* <button className="history-cta">CONTACT US</button> */}
        </div>
        <div className="history-image">
          <img src={elilocklogo} alt="elilock"/>
        </div>
      </div>
    </section>
  );
};

export default History;
