import React from "react";

import "./OrderHistory.scss";
import { useNavigate } from "react-router-dom";
import ListOfOrders from "./ListOfOrders";
const OrderHistory = () => {
  const navigate = useNavigate();

  const openOrderDetails = (id) => {
    navigate(`/order-details/${id}`);
  };

  return (
    <section>
      <div className="container vh">
        <h2>Order History</h2>
        <p>
          Click an Order to leave <b>a review</b>
        </p>
        <br />
        <ListOfOrders openOrderDetails={openOrderDetails} />
      </div>
    </section>
  );
};

export default OrderHistory;
