import React from "react";
import { reviews } from "../../utils/reviewsData";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/splide.min.css";
import "./Testimonials.css";

import Quote from "../../assets/blockquote.svg";

const Testimonials = () => {
  return (
    <section className="container testimonial-container">
      <div className="testimonial-title">
        <h1>Testimonials</h1>
        <p>What Our Customers are Saying</p>
      </div>
      <div className="testimonial-slider-container">
        <blockquote>
          <img
            src={Quote}
            alt="Quote"
            className="testimonial-quote top-quote"
          />
          <img
            src={Quote}
            alt="Quote"
            className="testimonial-quote bottom-quote"
          />
        </blockquote>
        <Splide
          option={{ perPage: 1, type: "fade", speed: 3000, autoplay: true }}
        >
          {reviews.map((review) => (
            <SplideSlide key={review.id} className="testimonial-item">
              <img
                src={review.image}
                alt="customer"
                className="testimonial-review-img"
              />
              <div className="testimonial-review-content">
                <p className="testimonial-text">{review.text}</p>
                <div className="testimonial-info">
                  <div className="testimonial-rating">
                    <span className="testimonial-star">&#9733;</span>
                    <span className="testimonial-star">&#9733;</span>
                    <span className="testimonial-star">&#9733;</span>
                    <span className="testimonial-star">&#9733;</span>
                    <span className="testimonial-star">&#9733;</span>
                    <span className="testimonial-star">&#9733;</span>
                  </div>
                  <p className="testimonial-user">{review.name}</p>
                </div>
              </div>
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </section>
  );
};

export default Testimonials;
